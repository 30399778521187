import React from 'react';
import { usePlaybookTheme } from 'playbook';
import { CoachSessionStyle } from 'graphql/types';
import Tag from './Tag';

type Props = {
  type?: CoachSessionStyle | null;
};

const SessionTag: React.FC<Props> = ({ type }) => {
  const theme = usePlaybookTheme();

  switch (type) {
    case CoachSessionStyle.Virtual:
      return (
        <Tag
          style={{
            backgroundColor: theme.colors.blue[100],
            color: theme.colors.blue[900],
          }}
        >
          Virtual
        </Tag>
      );
    case CoachSessionStyle.InPerson:
      return (
        <Tag
          style={{
            backgroundColor: theme.colors.aquamarine[100],
            color: theme.colors.aquamarine[900],
          }}
        >
          In-Person
        </Tag>
      );
    default:
      return null;
  }
};

export default SessionTag;
