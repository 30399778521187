export const colors = {
  blue: {
    50: '#E7E8F0' as const,
    100: '#C2C5DB' as const,
    200: '#9BA0C2' as const,
    300: '#767BAA' as const,
    400: '#5B6099' as const,
    500: '#414589' as const,
    600: '#3B3E80' as const,
    700: '#2C2C69' as const,
    800: '#201C52' as const,
    900: '#0E0A3B' as const,
  },
  aquamarine: {
    50: '#E0F9F0' as const,
    100: '#B3EFD9' as const,
    200: '#7BE5C1' as const,
    300: '#00DBA8' as const,
    400: '#00D294' as const,
    500: '#00C781' as const,
    600: '#00B774' as const,
    700: '#00A466' as const,
    800: '#009258' as const,
    900: '#007140' as const,
  },
  brightBlue: {
    50: '#DFFBFB' as const,
    100: '#AFF4F5' as const,
    200: '#74EEF1' as const,
    300: '#02E6ED' as const,
    400: '#00DEEA' as const,
    500: '#00D7E8' as const,
    600: '#00C6D4' as const,
    700: '#00B0B9' as const,
    800: '#009BA1' as const,
    900: '#007673' as const,
  },
  gray: {
    1: '#FFFFFF' as const,
    50: '#F2F2F2' as const,
    100: '#E9E9E9' as const,
    200: '#D9D9D9' as const,
    300: '#C4C4C4' as const,
    400: '#9D9D9D' as const,
    500: '#7B7B7B' as const,
    600: '#555555' as const,
    700: '#434343' as const,
    800: '#201C21' as const,
    900: '#000000' as const,
  },
  coolGray: {
    50: '#FBFCFE' as const,
    100: '#F2F4F6' as const,
    200: '#E5EAEF' as const,
    300: '#D8E1E7' as const,
    400: '#B9C2CA' as const,
    500: '#AAB6BE' as const,
    600: '#69757F' as const,
    700: '#46525C' as const,
    800: '#323A41' as const,
    900: '#282F34' as const,
  },
  red: {
    50: '#FEEDF0' as const,
    100: '#FED3D8' as const,
    200: '#EFA4A6' as const,
    300: '#E68283' as const,
    400: '#F26765' as const,
    500: '#F9584D' as const,
    600: '#EB514C' as const,
    700: '#D94845' as const,
    800: '#CC413E' as const,
    900: '#BD3732' as const,
  },
  orange: {
    50: '#FAEFE1' as const,
    100: '#F4D7B4' as const,
    200: '#ECBE85' as const,
    300: '#E6A455' as const,
    400: '#E39131' as const,
    500: '#DF7F18' as const,
    600: '#DB7517' as const,
    700: '#D46615' as const,
    800: '#CD5713' as const,
    900: '#C33E0E' as const,
  },
};

export const colorVariations = {
  opacity: {
    30: {
      coolGray: {
        900: 'rgba(40, 47, 52, 0.19)' as const,
      },
    },
    50: {
      coolGray: {
        900: 'rgba(40, 47, 52, 0.31)' as const,
      },
    },
    60: {
      gray: {
        1: `rgba(255, 255, 255, 0.38)` as const,
      },
    },
  },
};

export const namedColors = {
  exosBlue: colors.blue[800],
  exosBrightBlue: colors.brightBlue[300],
  exosPurple: colors.blue[900],
  exosAquamarine: colors.aquamarine[300],
  exosWhite: colors.gray[1],
  exosGray: colors.gray[50],
  exosCoolGray: colors.coolGray[700],
  exosSteel: colors.gray[800],
  exosBlack: colors.gray[900],
  exosRed: colors.red[300],
  exosOrange: colors.orange[200],
};

export const colorAliases = {
  // Main

  /** Most cards and items that appear closest to the member. */
  mainSurface: colors.coolGray[100],
  /** Dividers, borders (where applicable) on the surface. */
  mainSurfaceDivider: colors.coolGray[200],
  /** Primary background color for most pages. */
  mainBackground: colors.gray[1],
  /** Dividers, borders (where applicable) on the background. */
  mainBackgroundDivider: colors.coolGray[100],
  /** Overlay has transparency. This is used behind a modal. */
  mainPrimaryOverlay: colorVariations.opacity[30].coolGray[900],
  /** Overlay has transparency. This is used behind a modal. */
  mainSecondaryOverlay: colorVariations.opacity[50].coolGray[900],
  /** Overlay for component bgs on components using imagery, for example. */
  mainContrastOverlay: colorVariations.opacity[60].gray[1],

  // Texts

  /** Use to emphasize texts. typically for Headers. */
  textPrimary: colors.gray[800],
  /** Use for subtitles. Less emphasis. */
  textSecondary: colors.coolGray[700],
  /** The ligh-test text we use. */
  textTertiary: colors.coolGray[600],
  /** Replaceable text indicator */
  textPlaceholder: colors.coolGray[600],
  /** Disabled text should be paired with disabled bg */
  textDisabled: colors.coolGray[600],
  /** Only used for permalinks/hyperlinks */
  textLink: colors.gray[800],
  /** Used on contrast. */
  textContrast: colors.gray[1],

  // Icons

  /** Color used to emphasize icons. */
  iconPrimary: colors.gray[800],
  /** Color used for secondary icons. */
  iconSecondary: colors.coolGray[600],
  /** Used on contrast. */
  iconContrast: colors.gray[1],

  // Primary

  /** Use for the background of Primary buttons, input controls such as radio buttons, checkboxes, etc. */
  primary: colors.gray[800],
  /** Hover color seen on an Primary interactive component such as a button. */
  primaryHover: colors.coolGray[800],
  /** Color shown when an interactive component is pressed. */
  primaryPressed: colors.gray[900],
  /** Disabled element */
  primaryDisabled: colors.coolGray[200],

  // Secondary

  /** Use for background of Secondary buttons and icons */
  secondary: colors.aquamarine[300],
  /** Hover color of Secondary interactive component such as a button. */
  secondaryHover: colors.aquamarine[400],
  /** Color shown when an interactive component is pressed. */
  secondaryPressed: colors.aquamarine[400],
  /** Disabled element */
  secondaryDisabled: colors.coolGray[200],

  // Contrast

  /** Use for background of Secondary buttons and icons */
  contrast: colors.gray[1],
  /** Hover color of Secondary interactive component such as a button. */
  contrastHover: colors.coolGray[100],
  /** Color shown when an interactive component is pressed. */
  contrastPressed: colors.coolGray[200],
  /** Use for background of Secondary buttons and icons */
  contrastAccent: colors.aquamarine[300],

  // Multi-select

  /** Most cards and items that appear closest to the member. */
  multiSelectDefault: colors.coolGray[100],
  /** Dividers, borders (where applicable) on the surface. */
  multiSelectDefaultPressed: colors.coolGray[200],
  /** Use for background of Secondary buttons and icons */
  multiSelectSelected: colors.aquamarine[300],

  // Status

  /** Use for showing success. */
  statusSuccess: colors.aquamarine[300],
  /** Use for showing information such as in a banner. */
  statusInfo: colors.blue[400],
  /** Use for showing a warning message such as in a banner. */
  statusWarning: '#EA780E',
  /** Use for showing a danger message such as in a banner. */
  statusError: colors.red[900],
};
