import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useAuth } from '@teamexos/fit-shared';
import { useNavigate, useLocation } from 'react-router-dom';

const Callback = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn) {
      const urlSearchParams = new URLSearchParams(location.search);
      const redirect = urlSearchParams.get('redirect') || '/';

      navigate(redirect, { replace: true });
    }
  });

  return <Typography>Please wait</Typography>;
};

export default Callback;
