import React, { useCallback } from 'react';
import { MuiOtpInput, MuiOtpInputProps } from 'mui-one-time-password-input';
import { usePlaybookTheme } from 'playbook';
import useMixpanel from 'hooks/useMixpanel';
import { titleCase } from 'utils/helpers';

type Props = {
  label: string;
  mixpanelData: Record<string, string>;
} & MuiOtpInputProps;

const Passcode = ({ style, mixpanelData, ...props }: Props) => {
  const theme = usePlaybookTheme();
  const track = useMixpanel();

  const { label, onBlur } = props;

  const trackedOnBlur = useCallback(
    (value: string, isCompleted: boolean) => {
      track('User Action', {
        event_name: 'text_entered',
        event_type: 'screen interaction',
        event_label: titleCase(label),
        // if you need to override event_label (ex. to preserve case)
        // just pass the preferred event_label in mixpanelData
        ...mixpanelData,
      });

      if (onBlur) {
        onBlur(value, isCompleted);
      }
    },
    [label, mixpanelData, onBlur, track],
  );

  return (
    <MuiOtpInput
      onBlur={trackedOnBlur}
      sx={{ gap: 1 }}
      {...props}
      length={6}
      TextFieldsProps={{
        variant: 'outlined',
        style: {
          backgroundColor: theme.colors.alias.mainSurface,
        },
        'aria-label': 'Code',
      }}
    />
  );
};

export default Passcode;
