import {
  SplitClient,
  SplitFactoryProvider,
} from '@splitsoftware/splitio-react';

import React from 'react';
import useSplit from 'hooks/useSplit';

const SplitProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { config, userAttributes } = useSplit();

  const canLoadSplit = !!config?.core.authorizationKey;

  if (canLoadSplit) {
    return (
      <SplitFactoryProvider config={config} attributes={userAttributes}>
        {/*
          make the split client explicit here will make sure the split key is 
          updated once the self query is resolved and we have the user id.
        */}
        <SplitClient splitKey={config.core.key}>{children as any}</SplitClient>
      </SplitFactoryProvider>
    );
  }
  return children;
};

export default SplitProvider;
