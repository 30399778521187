import React, { ReactElement, ReactNode } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import exosLogo from 'assets/logo.png';
import exosLogo2x from 'assets/logo@2x.png';

export const LogoContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.baseUnit * 6,
  '& img': {
    maxWidth: '100%',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.baseUnit * 9,
  paddingLeft: theme.baseUnit * 8,
  paddingRight: theme.baseUnit * 8,
  flexDirection: 'column',
  paddingBottom: theme.baseUnit * 9,
  backgroundColor: theme.colors.gray['1'],
}));

interface Props {
  client?: {
    name?: string;
    logo_url?: string;
  };
  children: ReactNode | ReactNode[];
}

const Branding = ({ client, children }: Props): ReactElement => (
  <main>
    <Box display="flex" flexDirection="column" justifyContent="center">
      <LogoContainer display="flex" justifyContent="center">
        {client ? (
          <img src={client.logo_url} alt={client.name} />
        ) : (
          <img
            src={exosLogo}
            srcSet={`${exosLogo} 1x,${exosLogo2x} 2x`}
            alt="Exos"
          />
        )}
      </LogoContainer>
      {children}
    </Box>
  </main>
);

export default Branding;
