import React from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePlaybookTheme } from 'playbook';
import { ActivityType, MboClassSubtype } from 'graphql/types';
import Tag from './Tag';

export type ActivityTagType = MboClassSubtype | ActivityType;

const ActivityTag: React.FC<{ type: ActivityTagType }> = ({ type }) => {
  const theme = usePlaybookTheme();

  switch (type) {
    case ActivityType.Virtual:
    case MboClassSubtype.LiveVirtual:
      return (
        <Tag
          style={{
            backgroundColor: theme.colors.blue[100],
            color: theme.colors.named.exosBlue,
          }}
        >
          <FontAwesomeIcon
            icon={faCircle}
            fontSize={4}
            style={{ marginRight: 4 }}
          />
          Live
        </Tag>
      );
    case ActivityType.InPerson:
      return (
        <Tag
          style={{
            backgroundColor: theme.colors.aquamarine[100],
            color: theme.colors.aquamarine[900],
          }}
        >
          In-Person
        </Tag>
      );
    case ActivityType.Encore:
      return (
        <Tag
          style={{
            borderColor: theme.colors.blue[100],
            color: theme.colors.named.exosBlue,
          }}
        >
          Encore
        </Tag>
      );
    default:
      return null;
  }
};

export default ActivityTag;
