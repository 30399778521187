import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import QRCode from 'react-qr-code';
import Error from 'components/Error';
import useMixpanel from 'hooks/useMixpanel';

export const QR_REDIRECT_PATH = '/qr_redirect';

const RedirectQR: React.FC = () => {
  const navigate = useNavigate();
  const track = useMixpanel();
  const location = useLocation();

  // if it comes from /qr_redirect/* we need to remove the /qr_redirect from the path
  // so we can pass the correct path to the QR code
  const locationPath = location.pathname.replace(QR_REDIRECT_PATH, '');

  React.useEffect(() => {
    track('Screen View', {
      screen_name: 'qr_redirect',
      deeplink: locationPath,
    });
  }, [track, locationPath]);

  return (
    <main>
      <Error
        buttonText="Continue to Login Anyway"
        buttonType="secondary"
        titleText="This content is only available on our mobile app."
        errorTextNode={
          <>
            Scan the QR Code below to open the Exos app on your mobile device,
            or open this link from your mobile device instead. <br />
            <br />
            <QRCode
              value={`https://fit.teamexos.com${locationPath}${location.search}`}
            />
          </>
        }
        onClick={() => {
          track('User Action', {
            event_category: 'error',
            event_location: 'qr_redirect',
            event_name: 'back_button_click',
            deeplink: location.pathname,
          });
          navigate(`/`);
        }}
      />
    </main>
  );
};

export default RedirectQR;
