import type { FirebaseOptions } from '@firebase/app';

// these values may look like secrets but they are not; it is okay to publish them
export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAwq1h94VQozJMjYOBDXzCTFiNsvSuXDVU',
  authDomain: 'prince-f37cc.firebaseapp.com',
  projectId: 'prince-f37cc',
  storageBucket: 'prince-f37cc.appspot.com',
  messagingSenderId: '445536312029',
  appId: '1:445536312029:web:765a48497bcd81a6fdd934',
  measurementId: 'G-ZGXMH2BZQQ',
};

export const recaptchaToken: string =
  '6LfZV2EqAAAAAJHxqHlztDnb60hd5hchelEuEt-N';
