import React from 'react';
import { styled } from '@mui/material/styles';

export type FilterBtnProps = {
  text: string;
  checked: boolean;
  onClick: () => void;
  testID?: string;
};

const FilterBtn: React.FC<FilterBtnProps> = ({
  text,
  checked = false,
  onClick,
  testID,
}: FilterBtnProps) => (
  <Button
    data-tid={testID}
    checked={checked}
    onClick={onClick}
    aria-pressed={checked}
  >
    <Text checked={checked}>{text}</Text>
  </Button>
);

const Button = styled('button')<{ checked?: boolean }>(
  ({ theme: { colors, baseUnit }, checked }) => ({
    padding: `${baseUnit}px 12px`,
    borderRadius: `${baseUnit * 2}px`,
    marginRight: baseUnit,
    marginBottom: baseUnit,
    borderWidth: 0,
    display: 'inline-block',
    cursor: 'pointer',
    backgroundColor: checked ? colors.alias.primary : colors.alias.mainSurface,
  }),
);

const Text = styled('span')<{ checked?: boolean }>(({ theme, checked }) => ({
  textAlign: 'center',
  color: checked ? theme.colors.gray[1] : theme.colors.alias.textPrimary,
  fontSize: '14px',
  lineHeight: '14px',
  fontFamily: 'sharp600',
  textTransform: 'capitalize',
}));

export default FilterBtn;
