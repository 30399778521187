import React, { useMemo, useEffect } from 'react';
import { Button, Typography, Divider } from '@mui/material';

import { styled } from '@mui/material/styles';
import Modal from 'components/common/Modal';
import CoachCard from 'components/common/CoachCard';
import TimeDisplay from 'components/common/TimeDisplay';
import useMixpanel from 'hooks/useMixpanel';
import { getReadableShort } from 'utils/dates';
import {
  CoachSessionStyle,
  CoachSessionType,
  useCoachSessionQuery,
} from 'graphql/types';
import useIsJoinable from 'hooks/useIsJoinable';

const CoachCardContainer = styled('div')(({ theme }) => ({
  marginTop: theme.baseUnit,
  marginBottom: theme.baseUnit * 4,
}));

const DateInfo = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: 14,
  '&, & > *': {
    ...theme.typography.fontFamily.sharp600,
    color: theme.colors.gray[600],
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontFamily.sharp600,
  fontSize: 24,
  lineHeight: '32px',
  color: theme.colors.gray[800],
  marginBottom: 4,
}));
const Metadata = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontFamily.reader400,
  color: theme.colors.gray[600],
  fontSize: 12,
  lineHeight: 1,
  marginBottom: theme.baseUnit * 4,
}));
const Description = styled('div')(({ theme }) => ({
  ...theme.typography.fontFamily.reader400,
  fontSize: 16,
  lineHeight: '26px',
}));

interface ConsultDetailModalProps {
  open: boolean;
  id?: string | null;
  onClose: () => void;
}

const coachingSessionContent = {
  name: 'Coaching Session',
  description: (
    <Description>
      Meet 1:1 with your coach to get a game plan based on your life and goals.
      And check in anytime for extra accountability, motivation, and new ideas.
    </Description>
  ),
  eventCategory: 'coach',
  eventSubcategory: 'consults',
};

interface ContentByType {
  name: string;
  description: React.ReactNode;
  eventCategory: string;
  eventSubcategory: string;
}

const contentByType: {
  [key in CoachSessionType]: ContentByType;
} = {
  [CoachSessionType.PersonalTraining]: {
    name: 'Personal Training Session',
    description: (
      <Description>
        <p>
          Want to get the most out of your session? Of course you do! To get
          ready:
        </p>
        <ul>
          <li>
            Put on some comfy clothes you can easily move around in — plus some
            secure footwear.
          </li>
          <li>Grab a water bottle and towel.</li>
          <li>
            Set up your laptop, tablet, or phone so you and your coach can
            easily see each other.
          </li>
        </ul>
        <p>
          If you need to cancel within 24 hours of your session (or are just
          running late!), please message your coach.
        </p>
      </Description>
    ),
    eventCategory: 'coach',
    eventSubcategory: 'personal training',
  },
  [CoachSessionType.UserNutritionConsult]: {
    name: 'Nutrition Session',
    description: (
      <Description>
        Meet 1:1 with an Exos nutrition specialist and receive a meal plan
        tailored to your needs and goals.
      </Description>
    ),
    eventCategory: 'nutrition',
    eventSubcategory: 'consults',
  },
  [CoachSessionType.CoachingSession]: coachingSessionContent,
  [CoachSessionType.UserCoachingConsult]: coachingSessionContent,
};

const ConsultDetailModal: React.FC<ConsultDetailModalProps> = ({
  open,
  id,
  onClose,
}) => {
  const { data: sessionData } = useCoachSessionQuery({
    variables: { id: id ?? '' },
    skip: !id,
  });

  const event = sessionData?.coachSession;
  const content =
    contentByType[event?.sessionType ?? CoachSessionType.CoachingSession];

  const track = useMixpanel();

  useEffect(() => {
    if (!event) return;

    track('User Action', {
      event_category: content.eventCategory,
      event_location: 'home',
      event_name: 'view_session_detail',
      event_subcategory: content.eventSubcategory,
      event_type: 'screen interaction',
      schedule_id: event.id,
      coach_id: event.coach.id,
    });
  }, [event, track, content]);

  const { start, end } = event?.calendar ?? {};
  const { meetingUrl: joinUrl, joinableTime } = event ?? {};
  const isJoinable = useIsJoinable({
    startTime: start,
    endTime: end,
    joinableTime,
  });
  const button = useMemo(() => {
    const isInPerson = event?.sessionStyle === CoachSessionStyle.InPerson;
    const { eventCategory, eventSubcategory } = content ?? {};

    if (!event || isInPerson || !isJoinable || !joinUrl) return undefined;

    const handleModalButtonClick = () => {
      track('User Action', {
        event_location: 'coach_session_detail_modal',
        event_name: 'launch_session',
        event_type: 'active engagement',
        event_category: eventCategory,
        event_subcategory: eventSubcategory,
        schedule_id: event.id,
        coach_id: event.coach.id,
      });

      if (joinUrl) {
        window.open(joinUrl, '_blank');
      }
      onClose();
    };

    return <Button onClick={handleModalButtonClick}>Join Session</Button>;
  }, [event, onClose, track, content, isJoinable, joinUrl]);

  if (!event) {
    return null;
  }

  const onCloseWithTracking = () => {
    track('User Action', {
      event_category: content.eventCategory,
      event_location: 'coach_session_detail_modal',
      event_name: 'back_button_click',
      event_subcategory: content.eventSubcategory,
      event_type: 'screen interaction',
      schedule_id: event.id,
      coach_id: event.coach.id,
    });
    onClose();
  };

  const eventToShow = {
    name: content.name,
    startTime: event.calendar?.start,
    duration: event.calendar?.duration,
    coachName: `${event.coach.firstName} ${event.coach.lastName}`,
    coachImage: event.coach.avatar?.preview ?? '',
  };

  return (
    <Modal open={open} onClose={onCloseWithTracking} button={button}>
      <>
        {eventToShow.startTime && (
          <DateInfo>
            {getReadableShort(eventToShow.startTime)}
            {'\u00A0•\u00A0'}
            <TimeDisplay start={eventToShow.startTime} onlyTime />
          </DateInfo>
        )}
        <Title variant="h2">{eventToShow.name}</Title>
        <Metadata>{`${eventToShow.duration} min`}</Metadata>
        <Divider variant="fullWidth" />
        <CoachCardContainer>
          <CoachCard
            name={eventToShow.coachName}
            image={eventToShow.coachImage}
          />
        </CoachCardContainer>

        <Description>{content.description}</Description>
      </>
    </Modal>
  );
};

export default ConsultDetailModal;
