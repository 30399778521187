import React from 'react';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CoachCard from 'components/common/CoachCard';
import TimeDisplay from 'components/common/TimeDisplay';

type GenericCardProps = {
  title?: string;
  onClick: () => void;
  duration?: string | number;
  subtitle?: string;
  startTime?: string;
  coachName?: string;
  coachImage?: string;
  destination: string;
  tag?: React.ReactElement;
};

/**
 * A content-agnostic UI-rendering component.
 * Can be used for Classes, Sessions, or anything with GenericCardProps.
 */
const GenericCard: React.FC<GenericCardProps> = ({
  tag,
  title,
  onClick,
  duration,
  subtitle,
  startTime,
  coachName,
  coachImage,
  destination,
}) => (
  <Container onClick={onClick} to={destination}>
    <Details>
      <Row>
        {tag}
        {!!startTime && <Time start={startTime} onlyTime />}
        {!!duration && <Duration>{` • ${duration} min`}</Duration>}
      </Row>
      <Title role="heading" aria-level={3}>
        {title}
      </Title>
      {!!subtitle && (
        <Subtitle role="heading" aria-level={4}>
          {subtitle}
        </Subtitle>
      )}
    </Details>
    {!!coachName && (
      <CoachCard name={coachName} image={coachImage || ''} smallName />
    )}
  </Container>
);

export default GenericCard;

// C O N T A I N E R S
const Container = styled(Link)(({ theme }) => ({
  color: 'inherit',
  display: 'flex',
  padding: theme.baseUnit * 2,
  minWidth: 280,
  minHeight: 120,
  borderRadius: theme.baseUnit,
  flexDirection: 'column',
  textDecoration: 'none',
  backgroundColor: theme.colors.alias.mainBackground,
}));

const Details = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}));

const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: theme.baseUnit,
}));

// T E X T
const Title = styled('div')(({ theme }) => ({
  ...theme.typography.textVariant.title.xs,
  color: theme.colors.alias.textPrimary,
  marginBottom: theme.baseUnit / 2,
}));

const Subtitle = styled('div')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.xs,
  color: theme.colors.alias.textSecondary,
  marginBottom: theme.baseUnit / 2,
}));

const Time = styled(TimeDisplay)(({ theme }) => ({
  ...theme.typography.textVariant.label.s,
  color: theme.colors.alias.textSecondary,
  marginLeft: theme.baseUnit,
}));

const Duration = styled('div')(({ theme }) => ({
  ...theme.typography.textVariant.label.s,
  color: theme.colors.alias.textSecondary,
  marginLeft: theme.baseUnit / 2,
}));
