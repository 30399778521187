import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Typography,
  Divider,
  Skeleton,
  SkeletonProps,
} from '@mui/material';
import { captureException } from '@sentry/browser';
import { usePlaybookTheme } from 'playbook';

import { styled } from '@mui/material/styles';
import Modal from 'components/common/Modal';
import CoachCard from 'components/common/CoachCard';
import TimeDisplay from 'components/common/TimeDisplay';
import useMixpanel from 'hooks/useMixpanel';
import { getReadableShort, getDuration } from 'utils/dates';
import { ReactComponent as LocationIcon } from 'assets/icons/location-sharp.svg';
import {
  useMboClassQuery,
  useJoinLiveClassMutation,
  MboClassSubtype,
} from 'graphql/types';
import useIsJoinable from 'hooks/useIsJoinable';

const Skel: React.FC<SkeletonProps> = (props) => {
  const { style } = props;
  return <Skeleton {...props} style={{ transform: 'none', ...style }} />;
};

const ClassDetailsLoading = () => {
  const theme = usePlaybookTheme();
  return (
    <main>
      <Skel
        height={theme.baseUnit * 1.5}
        style={{ marginBottom: theme.baseUnit * 2 }}
      />
      <Skel
        height={theme.baseUnit * 4}
        style={{ marginBottom: theme.baseUnit / 2 }}
      />
      <Skel
        height={theme.baseUnit * 1.5}
        style={{ marginBottom: theme.baseUnit * 4 }}
      />

      <Divider variant="fullWidth" />
      <Skel
        height={theme.baseUnit * 2.5}
        width={theme.baseUnit * 18}
        style={{ marginBottom: theme.baseUnit * 4, marginTop: theme.baseUnit }}
      />

      <Skel height={theme.baseUnit * 22} />
    </main>
  );
};

interface ClassDetailModalProps {
  open: boolean;
  id?: string | null;
  onClose: () => void;
}

const ClassDetailModal = ({
  open,
  id: classId,
  onClose,
}: ClassDetailModalProps) => {
  const { data, loading } = useMboClassQuery({
    skip: !classId,
    variables: {
      input: {
        id: classId,
      },
    },
  });

  const navigate = useNavigate();
  const track = useMixpanel();
  const [trackActivity] = useJoinLiveClassMutation();

  const { startTime, endTime, joinableTime } = data?.mboClass ?? {};

  const isJoinable = useIsJoinable({
    startTime,
    endTime,
    joinableTime,
  });

  const button = useMemo(() => {
    if (loading || !data?.mboClass || !data.mboClass.joinUrl) return undefined;
    const { id, staff, studio, joinUrl, subtype } = data.mboClass;

    if (subtype !== MboClassSubtype.InPerson && isJoinable) {
      const handleModalButtonClick = () => {
        track('User Action', {
          event_name: 'launch_live_class',
          event_type: 'active engagement',
          event_category: 'live class',
          event_subcategory: 'virtual',
          event_location: 'class_live_detail_modal',
          coach_id: staff?.id,
          class_id: id,
          studio_id: studio.mboStudioId,
        });

        // Call track activity without blocking the UI
        trackActivity({
          variables: {
            classId: id,
          },
        }).catch((e) => {
          captureException(e);
        });
        if (joinUrl.includes('.m3u8')) {
          const splitUrl = joinUrl.split('/');
          const wistiaId = splitUrl[splitUrl.length - 1]?.replace('.m3u8', '');
          navigate(`/video/full/${wistiaId}/${id}`);
          // don't trigger "onClose" when we navigate to the fullscreen video player
          return;
        }
        window.open(joinUrl, '_blank');
        onClose();
      };

      return <Button onClick={handleModalButtonClick}>Launch class</Button>;
    }

    return undefined;
  }, [loading, data, isJoinable, track, trackActivity, navigate, onClose]);

  const modalContent = useMemo(() => {
    if (loading || !data?.mboClass || !startTime)
      return <ClassDetailsLoading />;

    const {
      staff,
      name,
      category,
      room,
      location,
      description,
      staffFirstName,
      staffLastName,
    } = data.mboClass;

    const duration = getDuration(startTime, endTime);

    return (
      <>
        <DateInfo>
          {getReadableShort(startTime)}
          {'\u00A0•\u00A0'}
          <TimeDisplay start={startTime} onlyTime />
        </DateInfo>
        <Title variant="h2">{name}</Title>
        <Metadata>
          {[`${duration} min`, category].filter(Boolean).join(' • ')}
        </Metadata>
        {[room, location?.name].some(Boolean) && (
          <Location>
            <LocationIcon style={{ marginRight: 6 }} />
            {[room, location?.name].filter(Boolean).join(', ')}
          </Location>
        )}
        <Divider variant="fullWidth" />
        <CoachCardContainer>
          <CoachCard
            name={`${staffFirstName ?? ''} ${staffLastName ?? ''}`}
            image={staff?.avatar?.preview ?? undefined}
          />
        </CoachCardContainer>
        {description !== undefined && (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </>
    );
  }, [loading, data, startTime, endTime]);

  useEffect(() => {
    if (!data?.mboClass) return;

    const { id, staff, studio } = data.mboClass;

    track('Screen View', {
      page_name: 'class_live_detail_modal',
      coach_id: staff?.id,
      class_id: id,
      studio_id: studio.mboStudioId,
    });
  }, [data, track]);

  return (
    <Modal open={open} onClose={onClose} button={button}>
      {modalContent}
    </Modal>
  );
};

export default ClassDetailModal;

const CoachCardContainer = styled('div')(({ theme }) => ({
  marginTop: theme.baseUnit,
  marginBottom: theme.baseUnit * 4,
}));

const DateInfo = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginBottom: 14,
  '&, & > *': {
    ...theme.typography.fontFamily.sharp600,
    color: theme.colors.gray[600],
    fontSize: 12,
    lineHeight: 1,
    letterSpacing: 0.5,
    textTransform: 'uppercase',
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontFamily.sharp600,
  fontSize: 24,
  lineHeight: '32px',
  color: theme.colors.gray[800],
  marginBottom: 4,
}));

const Metadata = styled(Typography)(({ theme }) => ({
  ...theme.typography.fontFamily.reader400,
  color: theme.colors.gray[600],
  fontSize: 12,
  lineHeight: 1,
  marginBottom: theme.baseUnit * 4,
}));

const Location = styled(Metadata)(({ theme }) => ({
  display: 'flex',
  alignevents: 'center',
  marginTop: theme.baseUnit * -2,
  marginBottom: theme.baseUnit * 3,
}));

const Description = styled('div')(({ theme }) => ({
  ...theme.typography.fontFamily.reader400,
  fontSize: 16,
  lineHeight: '26px',
}));
