import { LocalDate, ZonedDateTime, ZoneId } from '@js-joda/core';
import groupBy from 'lodash/groupBy';
import {
  CoachSessionNodeFragment,
  MboClassDetailsFragment,
} from 'graphql/types';

export type ScheduleItem = CoachSessionNodeFragment | MboClassDetailsFragment;

export type GroupedScheduleItems = Record<string, ScheduleItem[]>;

export const getDateFromScheduleItem = (
  item: ScheduleItem,
  localZone: ZoneId,
) => {
  if (item.__typename === 'CoachSession' && item.calendar?.start) {
    return ZonedDateTime.parse(item.calendar.start).withZoneSameInstant(
      localZone,
    );
  }
  if (item.__typename === 'MboClass') {
    return ZonedDateTime.parse(item.startTime).withZoneSameInstant(localZone);
  }

  return ZonedDateTime.now(localZone);
};

type ClassEdge = {
  __typename?: 'UserBookingsEdge';
  cursor: string;
  node: MboClassDetailsFragment;
};

type SessionEdge = {
  __typename?: 'UserCoachSessionsEdge';
  cursor: string;
  node: CoachSessionNodeFragment;
};

export const groupAndSortScheduleItems = (
  classEdges: ClassEdge[],
  sessionEdges: SessionEdge[],
  localZone: ZoneId,
): GroupedScheduleItems => {
  const nodes: ScheduleItem[] = [];
  classEdges.forEach((edge) => {
    nodes.push(edge.node);
  });
  sessionEdges.forEach((edge) => {
    if (edge.node.calendar) {
      nodes.push(edge.node);
    }
  });

  const sorted = nodes.sort((a, b) =>
    getDateFromScheduleItem(a, localZone).compareTo(
      getDateFromScheduleItem(b, localZone),
    ),
  );

  return groupBy(sorted, (item) =>
    getDateFromScheduleItem(item, localZone).toLocalDate().toString(),
  );
};

export const hasDayData = (
  date: LocalDate,
  scheduleItemsByLocalDate: GroupedScheduleItems,
) => !!scheduleItemsByLocalDate[date.toString()]?.length;
