import { colors as themeColors, colorAliases, namedColors } from './colors';
import { fontFamily, textVariant } from './typography';

export const baseUnit = 8;
export const colors = {
  ...themeColors,
  alias: colorAliases,
  named: namedColors,
  error: themeColors.red[800],
};

const themeBase = {
  baseUnit,
  typography: {
    fontFamily,
    textVariant,
  },
};

// L I G H T   M O D E
export const light = {
  ...themeBase,
  colors,
  currentTheme: 'light',
};

export type ThemeMode = 'light';
export type PlaybookTheme = typeof light;
