import type { Photo as GraphQLPhoto } from '../graphql/types';

const platformExtension = 'jpeg';
const WHITE_PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkMAYAADkANVKH3ScAAAAASUVORK5CYII=';
type Layout = { width: number; height: number };

export const urlGenerator = (
  basePath: string,
  id: string,
  w: number,
  h: number,
  extension: string = platformExtension,
): string =>
  basePath && id ? `${basePath}/${id}/${w}x${h}.${extension}` : WHITE_PIXEL;

export const computeUrl = (
  avatar: Pick<GraphQLPhoto, 'basePath' | 'id'> | undefined | null,
  { width, height }: Layout,
  extension: string = platformExtension,
): string => {
  if (!avatar) {
    return WHITE_PIXEL;
  }

  const { basePath, id } = avatar;
  const ratio = window.devicePixelRatio ?? 1;
  const w = Math.floor(ratio * width);
  const h = Math.floor(ratio * height);
  return urlGenerator(basePath, id, w, h, extension);
};
