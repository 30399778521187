import { useCallback, useEffect, useState } from 'react';
import { initialize } from '@iterable/web-sdk/dist/authorization';
import { track } from '@iterable/web-sdk/dist/events';
import { DateTimeFormatter, ZonedDateTime, ZoneId } from '@js-joda/core';
import { useIterableInitQuery } from 'graphql/types';
import { IterableEvent } from 'utils/iterable';
import {
  addBreadcrumb,
  captureException,
  captureMessage,
} from '@sentry/browser';

const ITERABLE_FORMAT = DateTimeFormatter.ofPattern(
  "yyyy-MM-dd'T'HH:mm:ss.SSSZZ",
);

export function formatIterableDate(
  zdt: ZonedDateTime | null | undefined,
): string | undefined {
  if (zdt instanceof ZonedDateTime) {
    return zdt.withZoneSameInstant(ZoneId.UTC).format(ITERABLE_FORMAT);
  }
  return undefined;
}

const useIterable = () => {
  const [isIterableInitialized, setisIterableInitialized] = useState(false);

  if (!process.env.REACT_APP_ITERABLE_KEY) {
    captureMessage('No Iterable API Key found');
  }

  const { data: selfData, refetch } = useIterableInitQuery({
    onError: (e) => {
      captureException(e);
    },
  });

  const getJWT = useCallback(async () => {
    try {
      const { data } = await refetch();
      const token = data?.self?.iterableToken?.accessToken;
      if (!token) {
        captureMessage('No Iterable token found');
      }
      return token || '';
    } catch (e) {
      captureException(e);
      throw e;
    }
  }, [refetch]);

  const iterableTrack = useCallback(
    (eventName: string, dataFields?: IterableEvent) => {
      try {
        addBreadcrumb({
          message: 'Iterable track',
          data: { eventName, dataFields },
        });
        track({
          eventName,
          dataFields: { ...dataFields },
        });
      } catch (e) {
        captureException(e);
      }
    },
    [],
  );

  useEffect(() => {
    if (selfData?.self?.email) {
      try {
        const { setEmail } = initialize(
          process.env.REACT_APP_ITERABLE_KEY || '',
          () => getJWT().then((response) => response),
        );
        setEmail(selfData?.self?.email).then(() => {
          setisIterableInitialized(true);
        });
      } catch (e) {
        captureException(e);
      }
    }
  }, [selfData?.self?.email, getJWT]);

  return {
    isIterableInitialized,
    iterableTrack,
  };
};

export default useIterable;
