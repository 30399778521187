import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';
import styled from '@emotion/styled';
import useMixpanel from 'hooks/useMixpanel';
import Branding from 'pages/login/Branding';
import { Title, Content, Subtitle } from './styles';

const QrCodeWrapper = styled.div({
  marginTop: 64,
  marginBOttom: 16,
});

export const CompleteOnMobile = () => {
  const track = useMixpanel();

  useEffect(() => {
    track('Screen View', {
      screen_name: 'qr_code_to_complete_enrollment_in_app',
    });
  }, [track]);

  return (
    <Branding>
      <Content display="flex" flexDirection="column" alignItems="center">
        <Title>You’re almost ready.</Title>
        <Subtitle>
          Finish creating your account by scanning the QR code below or opening
          the Exos app on your mobile device.
        </Subtitle>
        <QrCodeWrapper>
          <QRCode value="https://fit.teamexos.com" size={190} />
        </QrCodeWrapper>
      </Content>
    </Branding>
  );
};
