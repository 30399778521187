import React, { useEffect } from 'react';
import { ClassFilterProvider } from 'contexts/classFilterContext';
import { styled } from '@mui/material/styles';
import { visuallyHidden } from '@mui/utils';
import useMixpanel from 'hooks/useMixpanel';
import Classes from 'components/Classes';
import Schedule from 'components/schedule/Schedule';
import Container from 'components/Container';

const Home = () => {
  const track = useMixpanel();
  useEffect(() => {
    track('Screen View', {
      page_name: 'home',
    });
  }, [track]);

  return (
    <main>
      <h1 style={visuallyHidden}>Exos - Schedule and Classes</h1>
      <Schedule />
      <ClassFilterProvider>
        <ContainerStyled>
          <Classes />
        </ContainerStyled>
      </ClassFilterProvider>
    </main>
  );
};

export default Home;

const ContainerStyled = styled(Container)(({ theme }) => ({
  backgroundColor: theme.colors.gray['1'],
}));
