/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { usePlaybookTheme } from 'playbook';
import useMixpanel from 'hooks/useMixpanel';
import { MPEventType } from 'types/mixpanel';

declare global {
  interface Window {
    _wq: unknown[];
  }
}

interface WistiaPlayerProps {
  hashedId: string;
  videoId: string;
  videoFoam?: boolean;
  onPause?: (percentWatched: number) => void;
  onProgress?: (percentWatched: number) => void;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TFunc = (arg1?: any, arg2?: any) => void;

type TDialog = {
  doClose: TFunc;
};

interface TVideo {
  remove: TFunc;
  duration: () => number;
  time: () => number;
  bind: (name: string, cb: TFunc) => void;
  controls: {
    settingsButton: {
      dialog: TDialog;
    };
    captionsButton: {
      dialog: TDialog;
    };
    smallPlayButton: {
      buttonElement: HTMLButtonElement;
    };
  };
}

const WistiaPlayer = ({
  hashedId,
  videoId,
  videoFoam = true,
  onPause = () => {},
  onProgress = () => {},
}: WistiaPlayerProps) => {
  const track = useMixpanel();
  const theme = usePlaybookTheme();
  const [latestPlayerEvent, setLatestPlayerEvent] = useState('paused');

  let wasPaused = false;
  let videoWatched = false;
  const trackVideoEvent = (
    eventName: string,
    eventType?: MPEventType,
  ): void => {
    track('User Action', {
      event_name: eventName,
      event_type: eventType || 'screen interaction',
      event_category: 'on demand',
      event_location: 'video_on_demand',
      content_id: videoId,
    });
  };
  useEffect(() => {
    let handle: TVideo;

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && handle) {
        handle.controls.settingsButton.dialog.doClose();
        handle.controls.captionsButton.dialog.doClose();
      }
    };

    window._wq = window._wq || [];
    window._wq.push({
      id: hashedId,
      options: {
        playerColor: theme.colors.aquamarine[300],
      },
      onEmbedded: (video: TVideo) => {
        const videoLen = video.duration();
        handle = video;

        video.bind('enterfullscreen', () => {
          track('Screen View', {
            page_name: 'video_on_demand',
          });
        });
        video.bind('pause', () => {
          setLatestPlayerEvent('paused');
          const percWatched = video.time() / videoLen;
          if (percWatched <= 1) {
            onPause(percWatched);
          }
          wasPaused = true;
          trackVideoEvent('video_pause');
        });
        video.bind('play', () => {
          setLatestPlayerEvent('playing');
          if (wasPaused) {
            trackVideoEvent('video_resume');
          } else {
            trackVideoEvent('video_play');
          }
        });
        video.bind('seek', (seconds) => {
          setLatestPlayerEvent(`Seeked to ${seconds} seconds`);
        });
        video.bind('volumechange', (volume, isMuted) => {
          if (isMuted) {
            setLatestPlayerEvent('Volume muted');
          } else {
            setLatestPlayerEvent(
              `Volume set to ${(Number(volume) * 100).toFixed(0)}%`,
            );
          }
        });
        video.bind('secondchange', (seconds) => {
          const percWatched = video.time() / videoLen;
          if (seconds % 10 === 0 && percWatched <= 1) {
            onProgress(percWatched);
          }
          if (percWatched >= 0.7 && !videoWatched) {
            videoWatched = true;
            trackVideoEvent('video_watched', 'passive engagement');
          }
        });
        document.addEventListener('keydown', handleKeyDown);

        // Focus on the play button after the video is loaded
        video.controls.smallPlayButton.buttonElement.focus();
      },
    });
    return function onUnload() {
      if (handle) {
        handle.remove();
      }
      document.removeEventListener('keydown', handleKeyDown);

      trackVideoEvent('video_abandon');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div
        className={`wistia_embed wistia_async_${hashedId} videoFoam=${videoFoam}`}
        style={{ height: '100%', width: '100%' }}
      />
      <div
        role="region"
        aria-live="polite"
        style={{ display: 'hidden' }}
        aria-label={latestPlayerEvent}
      />
    </>
  );
};

export default WistiaPlayer;
