import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAuth } from '@teamexos/fit-shared';
import useMixpanel from 'hooks/useMixpanel';
import { Controller, useForm } from 'react-hook-form';
import { redirect, useLocation, useNavigate } from 'react-router-dom';
import { captureException } from '@sentry/browser';
import { Button } from 'components/common/Button';
import { usePlaybookTheme } from 'playbook';
import Password from 'components/common/fields/Password';
import { useSnackbar } from 'contexts/snackbarContext';
import { Content, TextButton, Subtitle, Title } from './styles';
import Branding from './Branding';

interface IFormData {
  password: string;
}

export const LoginNonSSO = (): ReactElement => {
  const { state } = useLocation();
  const theme = usePlaybookTheme();
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const locationState = state as
    | {
        client: { name: string; logo_url?: string };
        email: string;
        connection: string;
      }
    | undefined;
  const [isLoading, setLoading] = useState(false);

  const { authorizeWithPassword, forgotPasswordStart } = useAuth();

  const track = useMixpanel();
  const mixpanelData = useMemo(
    () => ({
      event_category: 'sign-in',
      event_location: 'registration_sign_in_non_sso',
    }),
    [],
  );

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<IFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (!locationState?.email) {
      redirect('/');
    }
  }, [locationState?.email]);

  const onSubmit = async (data: IFormData): Promise<void> => {
    try {
      setLoading(true);
      if (locationState?.email && locationState?.connection) {
        await authorizeWithPassword({
          username: locationState.email,
          password: data.password,
        });
      } else {
        throw new Error('Missing parameters');
      }

      track('User Action', {
        event_name: 'signin_non_sso',
        event_type: 'screen interaction',
        ...mixpanelData,
      });

      redirect('/');
    } catch (e) {
      // If we are missing parameters we reject without a message
      const reason = (e as Error)?.message || 'Wrong email or password.';

      captureException(e);

      setError('password', {
        type: 'manual',
        message: reason,
      });
    } finally {
      setLoading(false);
    }
  };

  const onClickForgotPassword = useCallback(() => {
    const sendCodeAndNavigate = async () => {
      if (!locationState || !locationState.email) {
        return;
      }

      track('User Action', {
        event_name: 'send_another_verification_code',
        event_type: 'screen interaction',
        event_category: 'sign-in',
        event_location: 'registration_sign_in_reset_password',
      });

      try {
        await forgotPasswordStart({
          email: locationState.email,
          connection: locationState.connection || '',
        });
        snackbar.show('Code sent!', 5000);
      } catch (e) {
        snackbar.show(
          'Unable to send code. Please try again in an hour.',
          5000,
        );
      } finally {
        navigate('/forgot-password', {
          state: {
            client: locationState?.client,
            email: locationState?.email,
          },
        });
      }
    };

    sendCodeAndNavigate();
  }, [forgotPasswordStart, locationState, navigate, snackbar, track]);

  return (
    <Branding client={locationState?.client}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Title>Enter your password</Title>
          <Subtitle>Log in by entering the password for your account.</Subtitle>
          <Controller
            name="password"
            defaultValue=""
            control={control}
            rules={{
              required: 'Password is required',
            }}
            render={({ field: { onChange, value } }) => (
              <Password
                isInvalid={!!errors.password}
                value={value}
                onChange={onChange}
                helperText={errors.password?.message?.toString()}
                mixpanelData={mixpanelData}
              />
            )}
          />

          {locationState?.email && locationState?.connection && (
            <TextButton onClick={onClickForgotPassword}>
              Forgot password?
            </TextButton>
          )}

          <Button
            style={{ marginTop: theme.baseUnit * 4 }}
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            disabled={!isValid || isLoading}
          >
            Log In
          </Button>
        </Content>
      </form>
    </Branding>
  );
};
