import { Skeleton } from '@mui/material';
import React, { useMemo } from 'react';
import { LocalDate, ZoneId } from '@js-joda/core';
import { styled } from '@mui/material/styles';
import Container from 'components/Container';
import useMixpanel from 'hooks/useMixpanel';

import { endOfDay, getReadableShort, startOfHour } from 'utils/dates';

import {
  DateFilter,
  CoachSessionType,
  useGetBookedClassesAndSessionsByDateRangeQuery,
  MboClassSubtype,
} from 'graphql/types';

import Slider from 'components/common/Slider';
import SectionTitle from 'components/common/SectionTitle';
import ScheduleEventDetailModals from './ScheduleEventDetailModals';
import { ScheduleItem, groupAndSortScheduleItems } from './util';
import ScheduleCard from './ScheduleCard';

/**
 * The schedule component
 * @returns ReactElement - The schedule component
 */
const Schedule = () => {
  const track = useMixpanel();

  // filter from top of the last hour to include in progress classes
  const start = startOfHour();
  const end = endOfDay(LocalDate.now().plusDays(14));

  const { data, loading } = useGetBookedClassesAndSessionsByDateRangeQuery({
    variables: {
      filter: {
        dates: {
          start: start.withFixedOffsetZone().toString(),
          end: end.withFixedOffsetZone().toString(),
        },
      },
      sessionDateFilter: [DateFilter.Future],
    },
  });

  const { bookedClasses, sessions } = data?.self ?? {
    bookedClasses: { edges: [] },
    sessions: { edges: [] },
  };

  const scheduleItemsByLocalDate = useMemo(
    () =>
      groupAndSortScheduleItems(
        bookedClasses.edges,
        sessions.edges,
        ZoneId.SYSTEM,
      ),
    [bookedClasses.edges, sessions.edges],
  );

  /**
   * Track mixpanel evnts for card click
   * @param item A schedule item
   */
  const trackMixpanelClick = (item: ScheduleItem) => {
    let eventName = '';
    let eventCategory = '';
    let eventSubcategory = '';

    const isCoachSession = item.__typename === 'CoachSession';

    const isClass = item.__typename === 'MboClass';

    let appendData: Record<string, string | number | undefined> = {};

    if (isCoachSession) {
      appendData.coach_id = item?.coach.id;
      eventSubcategory = 'consults';
      appendData.schedule_id = item.id;

      if (item.sessionType === CoachSessionType.UserCoachingConsult) {
        eventCategory = 'coach';
        eventName = 'view_coach_session_detail';
      }

      if (item.sessionType === CoachSessionType.UserNutritionConsult) {
        eventCategory = 'nutrition';
        eventName = 'view_dietitian_session_detail';
      }
    }

    if (isClass) {
      eventCategory = 'live class';
      eventName = 'view_live_class_detail';
      appendData = {
        ...appendData,
        class_id: item.id,
        studio_id: item.studio.id,
      };
      if (
        item.subtype === MboClassSubtype.LiveVirtual ||
        item.subtype === MboClassSubtype.Encore
      ) {
        eventSubcategory = 'virtual';
      }

      if (item.subtype === MboClassSubtype.InPerson) {
        eventSubcategory = 'in-person';
      }
    }

    track('User Action', {
      event_name: eventName,
      event_type: 'passive engagement',
      event_category: eventCategory,
      event_subcategory: eventSubcategory,
      event_location: 'home',
      ...appendData,
    });
  };

  const showSkeleton = loading && !data?.self;

  return (
    <Container>
      <SectionTitle role="heading" aria-level={2}>
        My Schedule
      </SectionTitle>
      <div>
        {showSkeleton ? (
          <Skeleton variant="rectangular" height={120} />
        ) : Object.keys(scheduleItemsByLocalDate).length ? (
          <Slider>
            {Object.keys(scheduleItemsByLocalDate).map((itemDate) => (
              <div data-day={itemDate} key={itemDate}>
                <DayLabel>
                  {/* Ensure that the date parser understands the date we are giving as a correct ISO in local time */}
                  {getReadableShort(`${itemDate}T00:00:00`)}
                </DayLabel>
                <DayContainer>
                  {scheduleItemsByLocalDate[itemDate].map((item) => (
                    <ScheduleCard
                      item={item}
                      onClick={() => trackMixpanelClick(item)}
                      key={item.id}
                    />
                  ))}
                </DayContainer>
              </div>
            ))}
          </Slider>
        ) : (
          <div>You have nothing booked for the next two weeks.</div>
        )}
      </div>
      <ScheduleEventDetailModals />
    </Container>
  );
};

export default Schedule;

const DayContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.baseUnit,
}));

const DayLabel = styled('div')(({ theme }) => ({
  ...theme.typography.textVariant.label.m,
  color: theme.colors.alias.textSecondary,
  marginBottom: theme.baseUnit,
}));
