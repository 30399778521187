import React, {
  createContext,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';

interface Props {
  children?: React.ReactNode;
}

export interface State {
  visible: boolean;
  text: string;
  timeout: number;
}

export type Action = {
  type: 'SET_VISIBLE';
  visible: boolean;
  text?: string;
  timeout: number;
};

type Context = {
  state: State;
  show: (text: string, timeout: number) => void;
};

export const defaultState: State = {
  visible: false,
  text: '',
  timeout: 2000,
};

export const defaultContext = {
  state: defaultState,
  show: (): void => {},
};

const SnackbarContext = createContext<Context>(defaultContext);

/**
 * The Snackbar context is a way to access the show() and state properties globally in order to triggr visibility of the snackbar from anywhere in the app
 */
const SnackbarContextProvider = ({ children }: Props): ReactElement => {
  const [timeoutId, updateTimeoutId] = useState<NodeJS.Timeout>();

  const [state, dispatch] = useReducer(
    (_currentState: State, action: Action): State => ({
      ..._currentState,
      visible: action.visible,
      timeout: action.timeout,
      ...(action.text && { text: action.text }),
    }),
    defaultState,
  );

  useEffect(() => () => timeoutId && clearTimeout(timeoutId), [timeoutId]);

  const snackbarContext = useMemo<Context>(
    () => ({
      state,

      show: (text: string, time = 2000) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }

        dispatch({
          type: 'SET_VISIBLE',
          visible: true,
          text,
          timeout: time,
        });

        const timeout = setTimeout(() => {
          dispatch({
            type: 'SET_VISIBLE',
            visible: false,
            timeout: time,
          });
        }, time);

        updateTimeoutId(timeout);
      },
    }),
    [state, timeoutId],
  );

  return (
    <SnackbarContext.Provider value={snackbarContext}>
      {children}
    </SnackbarContext.Provider>
  );
};

function useSnackbar(): Context {
  return useContext<Context>(SnackbarContext);
}

export { useSnackbar, SnackbarContextProvider };
