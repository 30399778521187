import { useCallback } from 'react';
import { sha1 } from 'crypto-hash';
import { MPTrack } from 'types/mixpanel';
import logger from 'utils/logger';
import { useMixpanel as useMixpanelBrowser } from '../utils/mixpanel';
import { useMixpanelHookQuery } from '../graphql/types';
import useSplit from './useSplit';

const useMixpanel = (): MPTrack => {
  const { data, loading } = useMixpanelHookQuery({
    fetchPolicy: 'cache-first',
  });
  const { track: splitTrack } = useSplit();

  const coachId = data?.self?.fitCoach?.id;
  const princeUserId = data?.self?.id;
  const heartUserId = data?.self?.email
    ? sha1(data?.self?.email).toString()
    : undefined;

  const mp = useMixpanelBrowser();

  return useCallback(
    async (name, passedInCfg): Promise<void> => {
      if (loading) {
        return;
      }
      const cfg = { coach_id: coachId, ...passedInCfg };
      logger.debug('TRACK', name, cfg);

      const env = process.env.NODE_ENV ?? 'development';

      // do not send Mixpanel events in development
      if (env !== 'development') {
        if (mp) {
          mp.track(name, {
            application_name: 'Exos Fit',
            user_id: heartUserId,
            sp_user_id: princeUserId,
            timestamp: new Date().toISOString(),
            ...cfg,
          });
        }

        // track to Split.io
        let eventName = ''; // handle type narrowing
        if (typeof cfg.event_name === 'string') {
          eventName = cfg.event_name;
        } else if (typeof cfg.screen_name === 'string') {
          eventName = `view_${cfg.screen_name}`;
        }

        if (eventName !== '') {
          // will avoid tracking if not have event_name or screen_name
          await splitTrack(eventName, undefined, {
            ...cfg,
          } as SplitIO.Properties);
        }
      }
    },
    [loading, coachId, mp, heartUserId, princeUserId, splitTrack],
  );
};
export default useMixpanel;
