import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import appImage from 'assets/exos-app.png';
import exosLogo from 'assets/logo.png';
import appStore from 'assets/appstore.png';
import googlePlay from 'assets/googleplay.png';

const AppOverlay = () => (
  <Container>
    <Top>
      <Logo src={exosLogo} alt="Exos" />
      <Text gutterBottom variant="h2">
        Get the full experience on the Exos app.
      </Text>
      <a href="https://apps.apple.com/us/app/exos-fit/id1570526785">
        <StoreButton src={appStore} alt="Download on the App Store" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=com.teamexos.exosfit">
        <StoreButton src={googlePlay} alt="Get it on Google Play" />
      </a>
    </Top>
    <AppImage src={appImage} alt="Install the Exos app" />
  </Container>
);

export default AppOverlay;

const Container = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: theme.colors.gray['1'],
}));

const Logo = styled('img')(({ theme }) => ({
  width: '90px',
  marginTop: theme.baseUnit * 6,
  marginBottom: theme.baseUnit * 3,
}));

const Text = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  paddingLeft: theme.baseUnit * 5,
  paddingRight: theme.baseUnit * 5,
  marginBottom: theme.baseUnit * 3,
}));

const StoreButton = styled('img')({ width: '178px', height: 'auto' });

const Top = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const AppImage = styled('img')(({ theme }) => ({
  marginTop: theme.baseUnit * 3,
  width: '100%',
  height: 'auto',
}));
