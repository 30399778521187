import React, { PropsWithChildren } from 'react';

import type { PlaybookTheme } from '../theme';
import { light as lightTheme } from '../theme';

type PlaybookThemeContext = { theme: PlaybookTheme };

export const ThemeContext = React.createContext<PlaybookThemeContext>({
  theme: lightTheme,
});

const theme = {
  theme: lightTheme,
};

const ThemeProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
);

export default ThemeProvider;
