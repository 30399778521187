import React from 'react';
import { Avatar } from '@mui/material';
import { styled } from '@mui/material/styles';

type IProps = {
  name: string;
  image?: string;
  smallName?: boolean;
  className?: string;
};

const CoachCard = ({ name, image, smallName = false }: IProps) => (
  <CardCoachRow>
    <CardCoachAvatar alt={name} src={image} />
    <CardCoachName style={{ fontSize: smallName ? 10.5 : 14 }}>
      {name}
    </CardCoachName>
  </CardCoachRow>
);

export default CoachCard;

const CardCoachRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

const CardCoachAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: theme.colors.blue['800'],
  width: 20,
  height: 20,
  marginRight: theme.baseUnit,
}));

const CardCoachName = styled('div')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  color: theme.colors.alias.textSecondary,
  marginTop: theme.baseUnit / 4,
}));
