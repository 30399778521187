import React from 'react';
import { styled } from '@mui/material/styles';
import WistiaPlayer from 'components/common/WistiaPlayer';
import {
  useGetOnDemandVideoDetailsQuery,
  useUpdateProgressMutation,
} from 'graphql/types';
import { addBreadcrumb, captureException } from '@sentry/browser';
import { IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Back } from 'assets/icons/back.svg';

interface FullScreenPlayerProps {
  contentId: string;
  hashedId: string;
  sectionId?: string;
}

const BackButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.baseUnit * 2,
  left: theme.baseUnit * 2,
  zIndex: 1,
  '& svg': {
    '& path': {
      stroke: 'white',
    },
  },
}));

const FullScreenPlayer: React.FC<FullScreenPlayerProps> = ({
  contentId,
  hashedId,
  sectionId,
}) => {
  const [updateProgress] = useUpdateProgressMutation();
  const navigate = useNavigate();

  const goBack = () => {
    if (window.history?.state?.idx > 0) {
      navigate(-1);
    } else {
      navigate('/', { replace: true });
    }
  };

  // Validating this is an on demand video, to determine whether to track progress
  const { data, loading } = useGetOnDemandVideoDetailsQuery({
    variables: {
      id: contentId,
    },
  });

  const onProgressEvent = (progress: number) => {
    if (data && data.content !== null) {
      try {
        addBreadcrumb({
          message: 'updating progress',
          data: {
            sectionId,
            contentId,
            progress,
          },
        });
        updateProgress({
          variables: {
            sectionId,
            contentId,
            progress,
          },
        });
      } catch (error) {
        captureException(error);
      }
    }
  };

  return (
    <FullScreenContainer id="fullScreenPlayerContainer">
      <BackButton aria-label="back" onClick={goBack}>
        <Back />
      </BackButton>
      {!loading && (
        <WistiaPlayer
          hashedId={hashedId}
          videoId={contentId}
          videoFoam={false}
          onPause={onProgressEvent}
          onProgress={onProgressEvent}
        />
      )}
    </FullScreenContainer>
  );
};

const FullScreenContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100vh',
  background: theme.colors.gray['900'],
  zIndex: 2000,
  display: 'flex',
  alignItems: 'center',
}));

export default FullScreenPlayer;
