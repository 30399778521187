import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/system';
import { usePlaybookTheme } from 'playbook';
import { Controller, useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@teamexos/fit-shared';
import Branding from 'pages/login/Branding';
import Passcode from 'components/common/fields/Passcode';
import Password from 'components/common/fields/Password';
import SnackBar from 'components/common/SnackBar';
import useMixpanel from 'hooks/useMixpanel';
import { Button } from 'components/common/Button';
import { useSnackbar } from 'contexts/snackbarContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import {
  Hint,
  Content,
  ErrorText,
  HeaderMessageContainer,
  TextButton,
  Subtitle,
  Title,
} from './styles';

export const ChangePassword: React.FC<{ message?: string }> = ({ message }) => {
  const track = useMixpanel();
  const theme = usePlaybookTheme();
  const snackbar = useSnackbar();
  const { state } = useLocation();
  const { forgotPasswordStart, forgotPasswordSubmit, authorizeWithPassword } =
    useAuth();
  const [hasHitLimit, setHasHitLimit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const locationState = state as
    | {
        client: { name: string; logo_url?: string };
        email: string;
        connection: string;
      }
    | undefined;

  const mixpanelData = useMemo(
    () => ({
      event_category: 'sign-in',
      event_location: 'registration_sign_in_reset_password',
    }),
    [],
  );

  useEffect(() => {
    track('Screen View', {
      screen_name: 'registration_sign_in_reset_password',
    });
  }, [track]);

  interface IFormData {
    code: string;
    password: string;
    confirmPassword: string;
  }

  const {
    watch,
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid },
  } = useForm<IFormData>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const password = watch('password');

  const onSendCode = async () => {
    if (!locationState || !locationState.email) {
      return;
    }

    setIsLoading(true);

    track('User Action', {
      event_name: 'send_another_verification_code',
      event_type: 'screen interaction',
      ...mixpanelData,
    });

    try {
      await forgotPasswordStart({
        email: locationState.email,
        connection: locationState.connection || '',
      });
      snackbar.show('Code sent!', 5000);
    } catch (e) {
      setHasHitLimit(true);
    }

    setIsLoading(false);
  };

  const onSubmit = async (data: IFormData): Promise<void> => {
    track('User Action', {
      event_name: 'reset_password',
      event_type: 'screen interaction',
      ...mixpanelData,
    });

    try {
      setIsLoading(true);
      if (locationState?.email) {
        await forgotPasswordSubmit({
          email: locationState.email,
          code: data.code,
          password: data.password,
        });
        await authorizeWithPassword({
          username: locationState.email,
          password: data.password,
        });
      }
      snackbar.show('Password updated.', 5000);
    } catch (e: any) {
      switch (e.code) {
        case 'CodeMismatchException':
          setError('code', {
            message:
              'Please re-enter the code properly. If the problem persists, try sending another code.',
          });
          break;
        case 'ExpiredCodeException':
          setError('code', {
            message: e.message,
          });
          break;
        case 'InvalidPasswordException':
        case 'LimitExceededException':
          setError('confirmPassword', {
            message: e.message,
          });
          break;
        default:
          setError('confirmPassword', {
            message:
              'We were unable to change your password. Kindly contact support.',
          });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Branding client={locationState?.client}>
      <form>
        <SnackBar />
        <Content>
          {message && (
            <HeaderMessageContainer>
              <FontAwesomeIcon icon={faInfoCircle} fontSize={20} />
              <Subtitle
                style={{
                  paddingLeft: theme.baseUnit * 2,
                  paddingRight: theme.baseUnit * 2,
                }}
              >
                {message}
              </Subtitle>
            </HeaderMessageContainer>
          )}
          <Title>Update your password</Title>
          <Subtitle>{`Verify it’s you by entering the code we sent to ${locationState?.email}. This code is valid for 1 hour.
Then, enter a new password for your account.`}</Subtitle>

          {/* C O D E */}
          <Controller
            name="code"
            defaultValue=""
            control={control}
            rules={{ validate: (value) => value.length === 6 }}
            render={({
              field: { onChange, value },
              fieldState: { invalid },
            }) => (
              <Box>
                <Passcode
                  onChange={onChange}
                  value={value}
                  length={6}
                  label="Verification Code"
                  mixpanelData={mixpanelData}
                />
                {invalid && <ErrorText>{errors.code?.message}</ErrorText>}
              </Box>
            )}
          />

          <TextButton
            style={{ marginBottom: theme.baseUnit * 2 }}
            onClick={onSendCode}
          >
            Send another code
          </TextButton>

          {hasHitLimit && (
            <ErrorText>
              You’ve hit the limit, please try again in an hour.
            </ErrorText>
          )}

          {/* P A S S W O R D */}
          <Controller
            name="password"
            defaultValue=""
            control={control}
            rules={{
              required: 'Password is required',
            }}
            render={({ field: { onChange, value } }) => (
              <Password
                isInvalid={!!errors.password}
                value={value}
                onChange={onChange}
                helperText={errors.password?.message?.toString()}
                label="New password"
                style={{ marginTop: theme.baseUnit * 2 }}
                mixpanelData={mixpanelData}
              />
            )}
          />
          <Hint>
            <>
              Password should:
              <br />
              • Be at least 12 characters long
              <br />
              • Contain a number
              <br />
              • Contain a special character
              <br />
              • Contain upper and lowercase letters
              <br />
            </>
          </Hint>
          <Controller
            name="confirmPassword"
            defaultValue=""
            control={control}
            rules={{
              required: 'Password confirmation is required.',
              validate: (value) =>
                value === password || 'The passwords do not match.',
            }}
            render={({ field: { onChange, value } }) => (
              <Password
                isInvalid={!!errors.confirmPassword}
                value={value}
                onChange={onChange}
                helperText={errors.confirmPassword?.message?.toString()}
                label="Confirm password"
                mixpanelData={mixpanelData}
              />
            )}
          />

          <Button
            onClick={handleSubmit(onSubmit)}
            loading={isLoading}
            disabled={!isValid || isLoading || hasHitLimit}
            style={{ marginTop: theme.baseUnit * 5 }}
          >
            Continue
          </Button>
        </Content>
      </form>
    </Branding>
  );
};
