import { useEffect, useState } from 'react';
import { ZonedDateTime, ChronoUnit } from '@js-joda/core';

type Props = {
  endTime?: string | ZonedDateTime | null;
  startTime?: string | ZonedDateTime | null;
  joinableTime?: string | ZonedDateTime | null;
};

export default ({ endTime, startTime, joinableTime }: Props): boolean => {
  const [isJoinable, setIsJoinable] = useState(false);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const now = ZonedDateTime.now();
    if (!endTime || !startTime || !joinableTime) {
      return undefined;
    }

    const classStart =
      typeof startTime === 'string'
        ? ZonedDateTime.parse(startTime)
        : startTime;
    const classEnd =
      typeof endTime === 'string' ? ZonedDateTime.parse(endTime) : endTime;

    const parsedJoinableTime =
      typeof joinableTime === 'string'
        ? ZonedDateTime.parse(joinableTime)
        : joinableTime;

    const isInProgress = now.isAfter(classStart) && now.isBefore(classEnd);
    const isEnded = now.isAfter(classEnd);

    if (isInProgress) {
      setIsJoinable(true);
    } else if (!isEnded) {
      const timeTilJoinable = now.until(parsedJoinableTime, ChronoUnit.MILLIS);
      timer = setTimeout(() => {
        setIsJoinable(true);
      }, timeTilJoinable);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [startTime, endTime, joinableTime]);

  return isJoinable;
};
