import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ClassDetailModal from './ClassDetailModal';
import ConsultDetailModal from './ConsultDetailModal';

/**
 * This smart component will look for schedule events id's in URL
 * (it means that a link was clicked to display event details).
 * If there is a valid id will show corresponded Modal component with event details
 */
const ScheduleEventDetailModals = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { scheduleEventId: id } = useParams<{
    scheduleEventId: string;
  }>();

  const isCoachSession = pathname.includes('session');
  const isMboClass = pathname.includes('class/');

  if (isCoachSession) {
    return <ConsultDetailModal open id={id} onClose={() => navigate('/')} />;
  }

  if (isMboClass) {
    return <ClassDetailModal open id={id} onClose={() => navigate('/')} />;
  }

  return null;
};

export default ScheduleEventDetailModals;
