import { Skeleton, Typography } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import useMixpanel from 'hooks/useMixpanel';
import { useGetContentGenresQuery } from 'graphql/types';
import logger from 'utils/logger';
import {
  CATCH_ALL_GENRE,
  useClassFilterContext,
} from 'contexts/classFilterContext';
import uniqueId from 'lodash/uniqueId';
import NestedPill from 'components/common/NestedPill';
import FilterBtn from './Filters/FilterBtn';

type Genre = {
  id: string | null;
  name: string;
  value: string;
  children?: Genre[];
  parent?: Genre | null;
};

const findGenreOrSubgenre = (genres: Genre[], genreValue: string) =>
  genres
    .flatMap((genre) => [genre, ...(genre.children ?? [])])
    .find((genre) => genre.value === genreValue);

const Skeletons = () => (
  <>
    {Array.from({ length: 10 }).map(() => (
      <Skeleton
        variant="rectangular"
        height={30}
        width={100}
        key={uniqueId()}
      />
    ))}
  </>
);

const ContentGenres: React.FC = () => {
  const { data, loading } = useGetContentGenresQuery({
    fetchPolicy: 'cache-first',
    onError: (error) => {
      logger.debug('Categories: useGetContentGenresQuery', error);
    },
  });
  const { state, setFilters } = useClassFilterContext();

  const mainContentGenres = useMemo(
    () => data?.contentGenres ?? [],
    [data?.contentGenres],
  );

  const [showingGenres, setShowingGenres] =
    useState<Genre[]>(mainContentGenres);

  useEffect(() => {
    setShowingGenres(mainContentGenres);
  }, [mainContentGenres]);

  useEffect(() => {
    // make sure the showingGenres will show the sub genres if the user has
    // a genre/subgenre selected (deep linking)
    const selectedGenreTitle = state.onDemand.genres?.[0];
    const genre = selectedGenreTitle
      ? findGenreOrSubgenre(mainContentGenres, selectedGenreTitle)
      : null;

    if (genre) {
      // check if every child of the genre is already showing
      const isShowingSubgenreList = genre.children?.every((child) =>
        showingGenres.some((item) => item.value === child.value),
      );
      const hasChildren = !!genre.children?.length;
      const isSubgenre = !!genre.parent;
      const showingSelectedSubgenre =
        isSubgenre &&
        showingGenres.length === 1 &&
        showingGenres[0].value === genre.value;

      if (isSubgenre && !showingSelectedSubgenre) {
        showSelectedSubGenre(genre);
      } else if (hasChildren && !isShowingSubgenreList) {
        showSubGenres(genre);
      }
    }
  }, [mainContentGenres, showingGenres, state.onDemand.genres]);

  const [height, setHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);

  const track = useMixpanel();

  const trackGenre = (genre: Genre | null, isActivating: boolean) => {
    const isSubgenre = !!genre?.parent;
    const eventName = isSubgenre
      ? 'browse_on_demand_by_subgenre'
      : 'browse_on_demand_by_genre';
    const eventSubcategory = genre ? genre.name : 'All Genres';
    const eventLabel = isActivating ? 'on' : 'off';
    const genreId = genre?.id;

    track('User Action', {
      event_name: eventName,
      event_type: 'screen interaction',
      event_category: 'on demand',
      event_subcategory: eventSubcategory,
      event_label: eventLabel,
      genreId,
      event_location: 'home',
    });
  };

  // list the "All genres", the selected genre and the list of (unselected) subgenres
  const showSubGenres = (genre: Genre) => {
    const subGenres = genre.children ?? [];
    setShowingGenres([genre, ...subGenres]);
  };

  // list the "All genres" and the selected subgenre
  const showSelectedSubGenre = (genre: Genre) => {
    setShowingGenres([genre]);
  };

  const onClickGenre = (genre: Genre) => {
    // Scroll to top of the genres list if the user is already scrolled down
    if (window.scrollY > height) {
      window.scrollTo({ top: height, behavior: 'smooth' });
    }

    if (genre.value === CATCH_ALL_GENRE.value) {
      setFilters('onDemand', {
        ...state.onDemand,
        genres: [CATCH_ALL_GENRE.value],
      });
      setShowingGenres(mainContentGenres);
      trackGenre(null, true);
      return;
    }

    const isSelecting = !state.onDemand.genres?.includes(genre.value);

    if (isSelecting) {
      const isSubgenre = !!genre.parent;
      const hasSubGenres = !!genre.children?.length;

      // is selecting a subgenre, only show the all genre and the subgenre
      // with the NestedPill component
      if (isSubgenre) {
        showSelectedSubGenre(genre);
      } else if (hasSubGenres) {
        // is selecting a genre with children, show the selected genre and
        // its unselected children
        showSubGenres(genre);
      }

      // apply the genre/subgenre filter
      setFilters('onDemand', {
        ...state.onDemand,
        genres: [genre.value],
      });

      trackGenre(genre, true);
    } else {
      // is unselecting a genre or subgenre

      // genre.parent contain the parent genre but not the lists of children
      // so we need to find the genre with the children
      const parentGenre =
        genre.parent &&
        findGenreOrSubgenre(mainContentGenres, genre.parent.value);

      // if has parent is unselecting a subgenre
      if (parentGenre) {
        showSubGenres(parentGenre);

        setFilters('onDemand', {
          ...state.onDemand,
          genres: [parentGenre.value],
        });
        trackGenre(genre, false);
        return;
      }

      // is unselecting a genre
      setShowingGenres(mainContentGenres);
      setFilters('onDemand', {
        ...state.onDemand,
        genres: [CATCH_ALL_GENRE.value],
      });
      trackGenre(genre, false);
    }
  };

  useEffect(() => {
    if (ref.current) {
      setHeight(ref.current.offsetHeight);
    }
  }, [setHeight]);

  return (
    <Container ref={ref}>
      {loading ? (
        <Skeletons />
      ) : data ? (
        <>
          <FilterBtn
            text={CATCH_ALL_GENRE.name}
            checked={
              state.onDemand.genres?.includes(CATCH_ALL_GENRE.value) ?? false
            }
            onClick={() => {
              onClickGenre({
                id: null,
                name: CATCH_ALL_GENRE.name,
                value: CATCH_ALL_GENRE.value,
              });
            }}
          />

          {showingGenres?.map((genre) => {
            const isChecked =
              state.onDemand.genres?.includes(genre.value) ?? false;
            const isSubgenre = !!genre.parent;

            if (isSubgenre && isChecked) {
              return (
                <NestedPill
                  key={`subgenre-${genre.value}`}
                  text={genre.name}
                  parentText={genre.parent?.name ?? ''}
                  onClick={() => {
                    onClickGenre(genre);
                  }}
                />
              );
            }
            return (
              <FilterBtn
                key={`genre-${genre.value}`}
                text={genre.name}
                checked={state.onDemand.genres?.includes(genre.value) ?? false}
                onClick={() => {
                  onClickGenre(genre);
                }}
              />
            );
          })}
        </>
      ) : (
        <Typography>No genres available.</Typography>
      )}
    </Container>
  );
};

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  // To allow left-align that considers the children's margin
  marginBottom: theme.baseUnit * 3,
  paddingTop: theme.baseUnit * 2,
  paddingBottom: theme.baseUnit,

  // Sticky header
  position: 'sticky',
  zIndex: 2,
  backgroundColor: theme.colors.alias.mainBackground,
  top: 0,
}));

export default ContentGenres;
