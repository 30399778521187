import React from 'react';
import { styled } from '@mui/material/styles';

type TagProps = {
  style?: React.CSSProperties;
} & React.PropsWithChildren;

const Tag: React.FC<TagProps> = ({ style, children }) => (
  <Container style={style}>{children}</Container>
);

export default Tag;

const Container = styled('div')(({ theme }) => ({
  ...theme.typography.textVariant.label.s,
  color: theme.colors.alias.textSecondary,
  padding: '3px 6px 3px 6px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: theme.baseUnit * 2,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'transparent',
  // tags "without" a border get an invisible border
  // so they'll be the same size as tags with a border
}));
