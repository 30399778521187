import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const Slider: React.FC<React.PropsWithChildren> = ({ children, ...rest }) => {
  /**
   * Keeps track of pagination data
   */
  const [pagination, setPagination] = useState<{
    current: number;
    total: number;
    hasNext: boolean;
    hasPrev: boolean;
  }>({
    current: 0,
    total: Infinity,
    hasNext: false,
    hasPrev: false,
  });

  /**
   * Set a ref that executes a callback when ready
   * https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
   */
  const [scrollEl, setScrollEl] = useState<HTMLDivElement>();

  /**
   * From the scroll position, set pagination data
   * @returns void
   */
  const calcPagination = useCallback(() => {
    if (!scrollEl) {
      return;
    }

    const left = scrollEl.scrollLeft;
    const win = scrollEl.clientWidth;
    const size = scrollEl.scrollWidth;

    const current = Math.ceil(left / win);
    const total = Math.ceil(size / win) - 1;
    const hasNext = current < total;
    const hasPrev = current > 0;

    const nextPaginationObject = {
      current,
      total,
      hasNext,
      hasPrev,
    };

    if (JSON.stringify(pagination) !== JSON.stringify(nextPaginationObject)) {
      setPagination(nextPaginationObject);
    }
  }, [pagination, scrollEl]);

  const scrollRef = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      setScrollEl(node);
    }
  }, []);

  useEffect(() => {
    if (!scrollEl) {
      return;
    }

    calcPagination();
  }, [scrollEl, children, calcPagination]);

  /**
   * Triggered when clicking next button
   * @returns void
   */
  const onPrevious = (): void => {
    if (!scrollEl) {
      return;
    }

    // scroll "container width" px each page
    const size = scrollEl.clientWidth;
    scrollEl.scrollBy({ left: -size, behavior: 'smooth' });
  };

  /**
   * Triggered when clicking prev button
   * @returns void
   */
  const onNext = () => {
    if (!scrollEl) {
      return;
    }

    // scroll "container width" px each page
    const size = scrollEl.clientWidth;
    scrollEl.scrollBy({ left: +size, behavior: 'smooth' });
  };

  return (
    <DataSlideContainer {...rest}>
      {pagination.hasPrev && (
        <ButtonContainer style={{ left: 0 }} onClick={onPrevious}>
          <FontAwesomeIcon icon={faChevronLeft} size="xl" width={34} />
        </ButtonContainer>
      )}

      <ItemsRow
        ref={scrollRef}
        hasPrev={pagination.hasPrev}
        hasNext={pagination.hasNext}
        onScroll={calcPagination}
      >
        {children}
      </ItemsRow>

      {pagination.hasNext && (
        <ButtonContainer style={{ right: 0 }} onClick={onNext}>
          <FontAwesomeIcon icon={faChevronRight} size="xl" width={34} />
        </ButtonContainer>
      )}
    </DataSlideContainer>
  );
};

const DataSlideContainer = styled('div')({
  position: 'relative',
});

const ButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  height: '100%',
  width: 60,
  top: 0,
  zIndex: 1,
  cursor: 'pointer',
});

const ItemsRow = styled('div')<{ hasPrev: boolean; hasNext: boolean }>(({
  theme,
  hasPrev,
  hasNext,
}) => {
  let linearGradient = '';
  if (hasPrev && hasNext) {
    linearGradient =
      'linear-gradient(to right, transparent 0%, black 15%, black 70%, transparent 100%)';
  } else if (hasPrev) {
    linearGradient = 'linear-gradient(to left, black 70%, transparent 100%)';
  } else if (hasNext) {
    linearGradient = 'linear-gradient(to right, black 70%, transparent 100%)';
  }

  return {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    overflowY: 'hidden',
    flex: 1,
    transition: 'mask-image 5s ease-out',
    padding: [theme.baseUnit * 2, 0],
    gap: theme.baseUnit * 3,
    maskImage: linearGradient,
    marginBottom: -20, // gets the scrollbar off the content
    paddingBottom: 20, // so you can see what you're scrolling
  };
});

export default Slider;
