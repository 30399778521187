import { useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { captureException } from '@sentry/browser';
import { useAuth } from '@teamexos/fit-shared';
import { App, useUpdateLastActivityMutation } from '../graphql/types';
import logger from '../utils/logger';

export default function useLastActivity() {
  const [updateLastActivityMutation] = useUpdateLastActivityMutation();
  const { isRegistrationComplete } = useAuth();
  const isVisible = usePageVisibility();

  useEffect(() => {
    const triggerLastActivity = async () => {
      try {
        if (!isRegistrationComplete || !isVisible) {
          return;
        }
        logger.debug('LAST ACTIVITY SAVED', { app: App.Heart });
        await updateLastActivityMutation();
      } catch (err) {
        captureException(err);
      }
    };

    triggerLastActivity();
  }, [updateLastActivityMutation, isRegistrationComplete, isVisible]);
}
