import { FilterContentsQuery, GetUserSavedVideosQuery } from 'graphql/types';

export function contentFilterQueryUpdater(
  prev: FilterContentsQuery,
  { fetchMoreResult }: { fetchMoreResult?: FilterContentsQuery },
): FilterContentsQuery {
  if (!fetchMoreResult) {
    return prev;
  }

  return {
    ...prev,
    ...fetchMoreResult,
    contents: {
      ...prev.contents,
      ...fetchMoreResult.contents,
      nodes: [...prev.contents.nodes, ...fetchMoreResult.contents.nodes],
    },
  };
}

export function savedContentFilterQueryUpdater(
  prev: FilterContentsQuery,
  { fetchMoreResult }: { fetchMoreResult?: FilterContentsQuery },
): FilterContentsQuery {
  if (!fetchMoreResult) {
    return prev;
  }

  return {
    ...prev,
    ...fetchMoreResult,
    contents: {
      ...prev.contents,
      ...fetchMoreResult.contents,
      nodes: [...prev.contents.nodes, ...fetchMoreResult.contents.nodes],
    },
  };
}

export function selfSavedContentQueryUpdater(
  prev: GetUserSavedVideosQuery,
  { fetchMoreResult }: { fetchMoreResult?: GetUserSavedVideosQuery },
): GetUserSavedVideosQuery {
  if (!fetchMoreResult) {
    return prev;
  }

  return {
    ...prev,
    ...fetchMoreResult,

    self:
      prev.self && fetchMoreResult.self
        ? {
            ...prev.self,
            ...fetchMoreResult.self,

            savedContent: {
              ...prev.self.savedContent,
              ...fetchMoreResult.self.savedContent,
              nodes: [
                ...prev.self.savedContent.nodes,
                ...fetchMoreResult.self.savedContent.nodes,
              ],
            },
          }
        : null,
  };
}
