import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import useWindowSize from 'hooks/useWindowSize';
import Authenticated from 'layouts/Authenticated';
import AppOverlay from 'components/common/AppOverlay';
import Guest from 'layouts/Guest';
import Container from 'components/Container';
import { Hub, useAuth } from '@teamexos/fit-shared';
import logger from 'utils/logger';
import {
  Breadcrumb,
  addBreadcrumb,
  captureException,
  captureMessage,
} from '@sentry/browser';
import { useNavigate } from 'react-router-dom';
import { useIdleTimerContext } from 'react-idle-timer';

const App = () => {
  const { width } = useWindowSize();
  const { pathname } = window.location;
  const { isLoading, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const { start } = useIdleTimerContext();

  useEffect(
    () =>
      Hub.listen('auth', ({ payload }) => {
        logger.debug('Hub event', {
          event: payload.event,
          message: payload.message,
        });

        const message = payload.message ? `:${payload.message}` : '';
        const breadcrumb: Breadcrumb = {
          category: 'cognito',
          message: `${payload.event}${message}`,
          level: 'info',
        };

        switch (payload.event) {
          case 'customOAuthState':
            // Don't log this as it may have the email in it
            break;
          case 'signInWithRedirect':
          case 'signedOut':
          case 'tokenRefresh':
            addBreadcrumb(breadcrumb);
            break;
          case 'signedIn':
            logger.debug('SignedIn User', payload.data);
            addBreadcrumb({
              ...breadcrumb,
              data: payload.data,
            });
            // eslint-disable-next-line no-case-declarations
            const redirectPath = localStorage.getItem('authRedirect') ?? '/';
            localStorage.removeItem('authRedirect');

            navigate(redirectPath);

            break;
          case 'signInWithRedirect_failure':
          case 'tokenRefresh_failure':
            logger.debug(payload.data);
            addBreadcrumb({
              ...breadcrumb,
              data: payload.data,
              level: 'error',
            });
            captureException(payload.data.error);
            break;
          default:
            captureMessage(`Unhandled event: ${(payload as any).event}`);
            logger.debug('Unhandled event');
        }
      }),
    [navigate],
  );

  useEffect(() => {
    if (isLoggedIn) {
      start();
    }
  }, [isLoggedIn, start]);

  const isPhone =
    width < 744 &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );
  if (
    isPhone &&
    !pathname.startsWith('/pepsico') &&
    !pathname.startsWith('/callback')
  ) {
    return <AppOverlay />;
  }

  if (isLoading) {
    return (
      <Container>
        <Typography>Loading...</Typography>
      </Container>
    );
  }

  return isLoggedIn ? <Authenticated /> : <Guest />;
};

export default App;
