import styled from '@emotion/styled';
import React from 'react';

type Props = {
  parentText: string;
  text: string;
  onClick: () => void;
};

const Pill = styled('button')(({ theme }) => ({
  fontFamily: 'sharp600',
  borderRadius: theme.baseUnit * 2,
  backgroundColor: '#3F4346',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  color: theme.colors.alias.textContrast,
  border: 'none',
  padding: 0,
  cursor: 'pointer',
  outline: 'inherit',
  paddingRight: 12,
  marginRight: theme.baseUnit,
  marginBottom: theme.baseUnit,
}));

const ParentPill = styled('div')(({ theme }) => ({
  borderRadius: theme.baseUnit * 2,
  backgroundColor: theme.colors.alias.primary,
  color: theme.colors.alias.textContrast,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: 12,
  paddingRight: 12,
  marginRight: theme.baseUnit,
  paddingTop: theme.baseUnit,
  paddingBottom: theme.baseUnit,
}));

const NestedPill = ({ parentText, text, onClick }: Props) => (
  <Pill onClick={onClick}>
    <ParentPill>{parentText}</ParentPill>
    <span>{text}</span>
  </Pill>
);

export default NestedPill;
