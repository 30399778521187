import { styled } from '@mui/material/styles';
import { Box, TextField } from '@mui/material';
import { LoadingButton as MUIButton } from '@mui/lab';

export const LogoContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.baseUnit * 6,
  '& img': {
    maxWidth: '100%',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.gray['1'],
  paddingTop: theme.baseUnit * 9,
  paddingBottom: theme.baseUnit * 9,
  paddingLeft: theme.baseUnit * 8,
  paddingRight: theme.baseUnit * 8,
}));

export const HeaderMessageContainer = styled(Box)(({ theme }) => ({
  padding: theme.baseUnit * 2,
  backgroundColor: theme.colors.aquamarine[50],
  borderRadius: theme.baseUnit,
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: theme.colors.aquamarine[400],
  marginBottom: theme.baseUnit * 4,
  display: 'flex',
  flexDirection: 'row',
}));

export const Button = styled(MUIButton)(({ theme }) => ({
  margin: `${theme.baseUnit * 4}px ${theme.baseUnit * 2}px !important`,
}));

// second prop is a workaround for a bug: https://github.com/mui/material-ui/issues/28844
export const FormField = styled(
  TextField,
  {},
)(({ theme }) => ({
  marginBottom: theme.baseUnit * 2,
}));

// T Y P O G R A P H Y
export const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.textVariant.title.l,
  color: theme.colors.alias.textPrimary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 3,
}));

export const Subtitle = styled('p')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.m,
  color: theme.colors.alias.textSecondary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 3,
}));

export const Note = styled('p')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  color: theme.colors.alias.textSecondary,
  marginTop: theme.baseUnit * 4,
  marginBottom: theme.baseUnit * 3,
}));

export const Hint = styled('p')(({ theme }) => ({
  ...theme.typography.textVariant.body.s,
  color: theme.colors.alias.textSecondary,
  lineHeight: 1.5,
  marginBottom: theme.baseUnit * 2,
}));

export const TextLink = styled('a')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  ...theme.typography.fontFamily.reader500,
  color: theme.colors.alias.textSecondary,
  display: 'block',
  margin: 0,
  marginTop: theme.baseUnit * 3,
  textDecorationLine: 'underline',
  cursor: 'pointer',
}));

export const TextButton = styled('button')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  ...theme.typography.fontFamily.reader500,
  color: theme.colors.alias.textSecondary,
  background: 'none',
  border: 'none',
  display: 'block',
  margin: 0,
  padding: 0,
  marginTop: theme.baseUnit * 3,
  textDecorationLine: 'underline',
  cursor: 'pointer',
  textAlign: 'left',
}));

export const Error = styled('p')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  color: theme.colors.alias.textContrast,
  margin: 0,
  textAlign: 'center',
  padding: theme.baseUnit * 2,
  backgroundColor: theme.colors.alias.statusError,
}));

export const ErrorText = styled('p')(({ theme }) => ({
  ...theme.typography.textVariant.body.s,
  color: theme.colors.error,
  margin: 0,
  marginTop: theme.baseUnit,
}));
