import React from 'react';
import { useNavigate } from 'react-router-dom';

import Error from 'components/Error';
import useMixpanel from 'hooks/useMixpanel';
import styled from '@emotion/styled';

const ErrorWrapper = styled('div')({
  display: 'flex',
  flex: 1,
});

const Page404: React.FC = () => {
  const navigate = useNavigate();
  const track = useMixpanel();

  React.useEffect(() => {
    track('Screen View', {
      screen_name: 'page_not_found',
    });
  }, [track]);

  return (
    <ErrorWrapper role="main">
      <Error
        buttonText="Go Back"
        errorTextNode={<>Looks like this page doesn’t exist.</>}
        onClick={() => {
          track('User Action', {
            event_category: 'error',
            event_location: 'page_not_found',
            event_name: 'back_button_click',
          });
          navigate(`/`);
        }}
      />
    </ErrorWrapper>
  );
};

export default Page404;
