import React from 'react';
import { PlaybookProvider, usePlaybookTheme } from 'playbook';
import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
} from '@mui/material/styles';
import { muiTheme } from 'styles/theme';

const ThemeProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const playbookTheme = usePlaybookTheme();

  return (
    <StyledEngineProvider injectFirst>
      <PlaybookProvider>
        <MuiThemeProvider
          theme={{
            ...muiTheme,
            baseUnit: playbookTheme.baseUnit,
            typography: {
              ...muiTheme.typography,
              ...playbookTheme.typography,
            },
            colors: playbookTheme.colors,
          }}
        >
          {children}
        </MuiThemeProvider>
      </PlaybookProvider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
