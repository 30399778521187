import {
  ChronoUnit,
  DateTimeFormatter,
  Duration,
  LocalDate,
  ZoneId,
  ZonedDateTime,
} from '@js-joda/core';
import '@js-joda/timezone';

export function getTimezone(): string {
  return ZonedDateTime.now().format(DateTimeFormatter.ofPattern('VV'));
}

export const timezone = ZoneId.of(getTimezone());

export const startOfHour = () =>
  ZonedDateTime.now().truncatedTo(ChronoUnit.HOURS);
export const startOfDay = (date: LocalDate) => date.atStartOfDay(timezone);
export const endOfDay = (date: LocalDate) =>
  date.plusDays(1).atStartOfDay(timezone).minusSeconds(1);

export const weekdaysFull: { [key: number]: string } = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const months: { [key: number]: string } = {
  0: 'January',
  1: 'February',
  2: 'March',
  3: 'April',
  4: 'May',
  5: 'June',
  6: 'July',
  7: 'August',
  8: 'September',
  9: 'October',
  10: 'November',
  11: 'December',
};

export function getYYYYMMDD(d: Date): string {
  const day = String(d.getDate());
  const month = String(d.getMonth() + 1);
  const year = d.getFullYear();
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export function monthAndDay(d: Date): string {
  const day = String(d.getDate());
  const month = months[d.getMonth()];
  return `${month} ${day}`;
}

export function getReadableShort(d: Date | string): string {
  return new Date(d).toLocaleDateString('en-US', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
  });
}

// Computes the difference between two dates, represented as strings, and rounds the result to a specified factor
export const getDuration = (startTime?: string, endTime?: string): string => {
  const dt1 = startTime && ZonedDateTime.parse(startTime);
  const dt2 = endTime && ZonedDateTime.parse(endTime);

  if (!dt1 || !dt2) {
    return '';
  }
  const duration = Duration.between(dt1, dt2); // 'PT10H'
  return String(duration.toMinutes());
};
