import { Shadows } from '@mui/material/styles/shadows';
import { createTheme } from '@mui/material/styles';
import { PlaybookTheme } from 'playbook';

const legacyColors = {
  aquamarine: '#00DBA8',
  aquamarine500: '#00C781',
  azure: '#00ADD0',
  black: '#000000',
  blue: '#201C52',
  brightBlue: '#02E6ED',
  carbon: '#37424A',
  darkCarbon: '#20252A',
  error: '#CF6679',
  gray: '#F2F2F2',
  platinum: '#D1D4D3',
  steel: '#201C21',
  titanium: '#818A8F',
  white: '#FFFFFF',
};

export const muiTheme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: legacyColors.blue,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  shadows: Array(25).fill('none') as Shadows, // disables all drop shadows
  typography: {
    fontFamily: 'sharp600',
    h2: {
      fontSize: '30px',
      color: legacyColors.steel,
    },
    h4: {
      fontSize: '14px',
      color: legacyColors.steel,
      letterSpacing: '1.4px',
      lineHeight: '22px',
      fontFamily: 'reader400',
    },
    body1: {
      fontSize: '16px',
      lineHeight: '26px',
      fontFamily: 'reader400',
      letterSpacing: 0.5,
    },
    body2: {
      fontSize: '9px',
      color: legacyColors.steel,
      letterSpacing: '1.4px',
      lineHeight: '12px',
      textTransform: 'uppercase',
    },
  },
  breakpoints: {
    values: {
      xs: 743,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 14, // rounded buttons
          textTransform: 'none',
          fontFamily: 'reader400',
          fontSize: 17,
          paddingTop: 10,
          paddingBottom: 10,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          background: legacyColors.steel,
          color: legacyColors.white,
          padding: '8px 16px',
          fontSize: 14,
        },
      },
    },
  },
});
declare module '@mui/material/styles' {
  interface Theme {
    colors: PlaybookTheme['colors'];
    typography: PlaybookTheme['typography'];
    baseUnit: number;
  }

  type DefaultTheme = Theme;
}

export default muiTheme;
