import React from 'react';
import { getDuration } from 'utils/dates';
import {
  CoachSessionType,
  MboClassDetailsFragment,
  CoachSessionNodeFragment,
} from 'graphql/types';
import ActivityTag from 'components/common/tags/ActivityTag';
import SessionTag from 'components/common/tags/SessionTag';
import GenericCard from './GenericCard';

type ICardProps = {
  item: MboClassDetailsFragment | CoachSessionNodeFragment;
  onClick: () => void;
};

const Card: React.FC<ICardProps> = ({ item, onClick }) => {
  // C L A S S
  if (item.__typename === 'MboClass') {
    const duration = getDuration(item.startTime, item.endTime);

    return (
      <GenericCard
        onClick={onClick}
        destination={`/class/${item.id}`}
        title={item.name}
        subtitle={item.category}
        duration={duration}
        startTime={item.startTime}
        coachName={`${item.staffFirstName} ${item.staffLastName}`}
        coachImage={item.staff?.avatar?.preview ?? ''}
        tag={<ActivityTag type={item.subtype} />}
      />
    );
  }

  // C O N S U L T
  if (item.__typename === 'CoachSession') {
    let title;
    switch (item.sessionType) {
      case CoachSessionType.PersonalTraining:
        title = 'Personal Training Session';
        break;
      case CoachSessionType.UserNutritionConsult:
        title = 'Nutrition Session';
        break;
      default:
        title = 'Coaching Session';
    }

    return (
      <GenericCard
        onClick={onClick}
        destination={`/session/${item.id}`}
        title={title}
        duration={item.calendar?.duration}
        startTime={item.calendar?.start}
        coachName={`${item.coach.firstName} ${item.coach.lastName}`}
        coachImage={item.coach.avatar?.preview ?? ''}
        tag={<SessionTag type={item.sessionStyle} />}
      />
    );
  }
  return null;
};

export default Card;
