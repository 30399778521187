import React, { useState } from 'react';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import NewTextField, { NewTextFieldProps } from './NewTextField';

const Password = ({ ...rest }: NewTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  return (
    <NewTextField
      label="Password"
      inputProps={{
        type: showPassword ? 'text' : 'password',
        autoComplete: 'current-password',
      }}
      iconRight={showPassword ? faEye : faEyeSlash}
      onIconRightClick={handleClickShowPassword}
      {...rest}
    />
  );
};

export default Password;
