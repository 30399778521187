import React from 'react';
import { Outlet, Route } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Container from 'components/Container';
import Evernorth from 'pages/login/Evernorth';
import Start from 'pages/login/Start';
import { LoginNonSSO } from 'pages/login/LoginNonSSO';
import Callback from 'pages/login/Callback';
import { ChangePassword } from 'pages/login/ChangePassword';
import { CompleteOnMobile } from 'pages/login/CompleteOnMobile';
import { supportedDeepLinks } from 'utils/deeplinks';
import RedirectQR from 'pages/RedirectQR';
import { SentryRoutes } from 'utils/sentry';

const deeplinks = Object.keys(supportedDeepLinks);

const App = () => (
  <SentryRoutes>
    {/* 
        NOTE: after add a new route, make sure to add it
        to the .pa11yci.js file to be included in the accessibility tests
      */}
    <Route path="/pepsico" element={<Evernorth />} />
    <Route
      element={
        <GuestLayout>
          <AuthenticationContainer maxWidth="xs">
            <Outlet />
          </AuthenticationContainer>
        </GuestLayout>
      }
    >
      <Route path="/" element={<Start />} />
      <Route path="/start/:email/:clientId?" element={<Start />} />
      <Route path="/login" element={<LoginNonSSO />} />
      <Route path="/complete-on-mobile" element={<CompleteOnMobile />} />
      <Route path="/callback" element={<Callback />} />
      <Route path="/forgot-password" element={<ChangePassword />} />
      <Route
        path="/password-expired"
        element={
          <ChangePassword message="Your password has expired. Please create a new password." />
        }
      />
      {/* Redirect To App */}
      {deeplinks.map((link) => (
        <Route path={link} key={link} element={<RedirectQR />} />
      ))}
      <Route path="*" element={<Start />} />
    </Route>
  </SentryRoutes>
);

export default App;

const GuestLayout = styled('div')({
  flex: 1,
  paddingTop: '7%',
});

const AuthenticationContainer = styled(Container)({
  width: 500,
  padding: 0,
});
