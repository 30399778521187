import { useContext } from 'react';

import { ThemeContext } from '../providers/ThemeProvider';

const usePlaybookTheme = () => {
  const { theme } = useContext(ThemeContext);

  return theme;
};

export default usePlaybookTheme;
