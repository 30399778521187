const weight = (w: string) => ({ fontWeight: Number(w) as any });

const lineHeight = (n: number) => ({ lineHeight: `${n}px` });

export const fontFamily = {
  reader400: {
    fontFamily: 'Reader',
    ...weight('400'),
  },
  reader500: {
    fontFamily: 'Reader-Medium',
    ...weight('500'),
  },
  reader700: {
    fontFamily: 'Reader-Bold',
    ...weight('700'),
  },
  reader400italic: {
    fontFamily: 'ReaderPro-Italic',
    ...weight('400'),
  },
  reader500italic: {
    fontFamily: 'ReaderPro-MediumItalic',
    ...weight('500'),
  },
  reader700italic: {
    fontFamily: 'ReaderPro-BoldItalic',
    ...weight('700'),
  },
  sharp500: {
    fontFamily: 'SharpSansDisplayNo2-Medium',
    ...weight('500'),
  },
  sharp600: {
    fontFamily: 'SharpSansDisplayNo2-Semibold',
    ...weight('600'),
  },
  sharp700: {
    fontFamily: 'SharpSansDisplayNo2-Bold',
    ...weight('700'),
  },
  sharp500italic: {
    fontFamily: 'SharpSansDisplayNo2-MediumOblique',
    ...weight('500'),
  },
  sharp600italic: {
    fontFamily: 'SharpSansDisplayNo2-SemiboldOblique',
    ...weight('600'),
  },
  sharp700italic: {
    fontFamily: 'SharpSansDisplayNo2-BoldOblique',
    ...weight('700'),
  },
};

export type TextVariantSizes = {
  title: 'xs' | 's' | 'm' | 'l' | 'xl';
  subtitle: 'xs' | 's' | 'm';
  body: 's' | 'm' | 'l';
  button: 'xs' | 's' | 'm' | 'l';
  label: 's' | 'm' | 'l';
};

export const textVariant: {
  [name in keyof TextVariantSizes]: {
    // So we're not depending on a native style declaration
    [size in TextVariantSizes[name]]: {
      fontWeight?: any;
      fontSize?: number;
      lineHeight?: any;
      letterSpacing?: number;
      textTransform?: any;
    };
  };
} = {
  title: {
    xs: {
      ...fontFamily.sharp600,
      fontSize: 16,
      ...lineHeight(20),
    },
    s: {
      ...fontFamily.sharp600,
      fontSize: 20,
      ...lineHeight(24),
    },
    m: {
      ...fontFamily.sharp600,
      fontSize: 24,
      ...lineHeight(30),
    },
    l: {
      ...fontFamily.sharp600,
      fontSize: 30,
      ...lineHeight(32),
    },
    xl: {
      ...fontFamily.sharp600,
      fontSize: 40,
      ...lineHeight(48),
    },
  },
  subtitle: {
    xs: {
      ...fontFamily.reader400,
      fontSize: 12,
      ...lineHeight(18),
    },
    s: {
      ...fontFamily.reader400,
      fontSize: 14,
      ...lineHeight(20),
    },
    m: {
      ...fontFamily.reader400,
      fontSize: 16,
      ...lineHeight(24),
    },
  },
  body: {
    s: {
      ...fontFamily.reader400,
      fontSize: 14,
      ...lineHeight(20),
    },
    m: {
      ...fontFamily.reader400,
      fontSize: 16,
      ...lineHeight(28),
    },
    l: {
      ...fontFamily.reader400,
      fontSize: 20,
      ...lineHeight(28),
    },
  },
  button: {
    xs: {
      ...fontFamily.sharp600,
      fontSize: 10,
      ...lineHeight(10),
      letterSpacing: 0.5,
      textTransform: 'uppercase',
    },
    s: {
      ...fontFamily.sharp600,
      fontSize: 12,
      ...lineHeight(12),
    },
    m: {
      ...fontFamily.sharp600,
      fontSize: 14,
      ...lineHeight(14),
    },
    l: {
      ...fontFamily.sharp600,
      fontSize: 16,
      ...lineHeight(16),
    },
  },
  label: {
    s: {
      ...fontFamily.sharp600,
      fontSize: 10,
      letterSpacing: 0.5,
      ...lineHeight(10),
      textTransform: 'uppercase',
    },
    m: {
      ...fontFamily.sharp600,
      fontSize: 12,
      letterSpacing: 0.5,
      ...lineHeight(12),
      textTransform: 'uppercase',
    },
    l: {
      ...fontFamily.sharp600,
      fontSize: 14,
      ...lineHeight(18),
    },
  },
};
