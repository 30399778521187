import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, useTheme } from '@mui/material';
import QRCode from 'react-qr-code';

import useWindowSize from 'hooks/useWindowSize';
import { Button } from 'components/common/Button';

import exosLogo from 'assets/logo.png';
import exosLogo2x from 'assets/logo@2x.png';
import healthylivingLogo from 'assets/healthyliving-logo.png';
import xIcon from 'assets/icons/x-icon.png';
import appStore from 'assets/appstore.png';
import googlePlay from 'assets/googleplay.png';

export const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.textVariant.title.l,
  color: theme.colors.alias.textPrimary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 3,
  [theme.breakpoints.down('xs')]: {
    ...theme.typography.textVariant.title.s,
  },
}));
export const MidTitle = styled('h1')(({ theme }) => ({
  ...theme.typography.textVariant.title.xs,
  color: theme.colors.alias.textPrimary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 3,
}));

export const Subtitle = styled('p')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.m,
  color: theme.colors.alias.textSecondary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 3,
  [theme.breakpoints.down('xs')]: {
    ...theme.typography.textVariant.subtitle.s,
  },
}));

export const Note = styled('p')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  color: theme.colors.alias.textSecondary,
  marginTop: theme.baseUnit * 2,
}));

const LogoContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.baseUnit * 6,
  textAlign: 'center',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  display: 'flex',
  width: 285,

  // Edge case
  '@media (max-width: 280px)': {
    maxWidth: '100%',
    flexDirection: 'column',
    height: 120,
  },
}));
const Image = styled('img')({
  maxWidth: '100%',
});

const FlexContainer = styled('div')(({ theme }) => ({
  paddingTop: '110px',
  paddingBottom: '110px',
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    paddingTop: '60px',
    paddingBottom: '60px',
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.colors.gray['1'],
  paddingTop: theme.baseUnit * 9,
  paddingBottom: theme.baseUnit * 9,
  paddingLeft: theme.baseUnit * 8,
  paddingRight: theme.baseUnit * 8,
}));
const CenteredView = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flex: 1,
  marginBottom: theme.baseUnit * 2,
}));

const Evernorth: React.FC = () => {
  const theme = useTheme();
  const { width } = useWindowSize();

  const isPhone =
    width < 744 &&
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  return (
    <FlexContainer>
      <header>
        <LogoContainer>
          <Image
            src={exosLogo}
            srcSet={`${exosLogo} 1x,${exosLogo2x} 2x`}
            alt="Exos"
            style={{ width: 92 }}
          />
          <Image src={xIcon} alt="X" style={{ width: 20 }} />
          <Image
            src={healthylivingLogo}
            style={{ width: 135 }}
            alt="Healthy Living"
          />
        </LogoContainer>
      </header>

      <main>
        <Content>
          <Title>Register with Evernorth</Title>
          <Subtitle>Please use Evernorth to access Exos.</Subtitle>
          <CenteredView>
            {isPhone ? (
              <>
                <a href="https://qr.evernorth.com/SzEZrS">
                  <Button
                    style={{
                      marginTop: theme.baseUnit * 4,
                      marginBottom: theme.baseUnit * 6,
                    }}
                  >
                    Continue with Evernorth
                  </Button>
                </a>
                <MidTitle>Don&apos;t have Evernorth installed?</MidTitle>
                <a href="https://apps.apple.com/us/app/evernorth/id6463806153">
                  <StoreButton src={appStore} alt="Download on the App Store" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.cigna.mobile.evernorth.healthkit&hl=en_US">
                  <StoreButton src={googlePlay} alt="Get it on Google Play" />
                </a>
              </>
            ) : (
              <QRCode value="https://qr.evernorth.com/SzEZrS" />
            )}
          </CenteredView>
          <Note>
            <strong>Having trouble?</strong> Try searching for Evernorth in your
            phone&apos;s app store.
          </Note>
          <Note>
            <strong>Having trouble registering?</strong>{' '}
            <a
              href="https://exosdigital.zendesk.com/hc/en-us/requests/new?ticket_form_id=13836393583764"
              target="_blank"
              rel="noreferrer"
            >
              Contact support
            </a>
            .
          </Note>
        </Content>
      </main>
    </FlexContainer>
  );
};

const StoreButton = styled('img')({ width: '178px', height: 'auto' });

export default Evernorth;
