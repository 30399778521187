export const removeUnderscores = (word: string): string =>
  word.replace(/_/g, ' ');

export const cleanUpWords = (words: string | string[]): string =>
  typeof words === 'string'
    ? removeUnderscores(words).split(' ').filter(Boolean).join(' ')
    : words
        .map((w) => removeUnderscores(w).split(' ').filter(Boolean).join(' '))
        .join(', ');

/**
 * Returns the value of a param from a given search query
 * @param search Url search parameters (?foo=1&bar=two)
 * @param param Parameter to search for
 * @returns Value of the searched param
 */
export const getSearchParam = (
  search: string,
  param: string,
): string | null => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get(param);
};

/**
 * Check if an email is valid
 * min domain atoms: 2
 */
export const isValidEmail = (email: string) =>
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(
    email,
  );

export const titleCase: (text?: string) => string | undefined = (text) =>
  text
    ? text
        .toLowerCase()
        .split(/\s+/)
        .map((part) => `${part[0]?.toUpperCase()}${part.slice(1)}`)
        .join(' ')
    : undefined;
