/* eslint-disable no-else-return */
import React, { createContext, ReactNode, useContext, useMemo } from 'react';
import { FirebaseApp, initializeApp } from '@firebase/app';
import {
  AppCheck,
  getToken,
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@firebase/app-check';
import { captureException } from '@sentry/browser';
import { firebaseConfig, recaptchaToken } from '../config/firebase';

interface FirebaseContext {
  app: FirebaseApp;
  appCheck?: AppCheck;
}

const Context = createContext<FirebaseContext | undefined>(undefined);

const syntheticAgents = [/site24x7/i, /CloudWatchSynthetics/i];

export const isSynthetic = (ua: string) =>
  syntheticAgents.some((agent) => ua.match(agent));

export const FirebaseProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const firebase = useMemo((): FirebaseContext => {
    const app = initializeApp(firebaseConfig, {
      name: 'prince-web',
      automaticDataCollectionEnabled: false,
    });
    let appCheck: AppCheck | undefined;
    if (!isSynthetic(window.navigator.userAgent)) {
      appCheck = initializeAppCheck(app, {
        provider: new ReCaptchaEnterpriseProvider(recaptchaToken),
        isTokenAutoRefreshEnabled: true,
      });
    }
    return { app, appCheck };
  }, []);

  return <Context.Provider value={firebase}>{children}</Context.Provider>;
};

export const useFirebase = (): FirebaseContext => {
  const result = useContext(Context);
  if (result) {
    return result;
  } else {
    throw new Error('firebase used outside of provider');
  }
};

export const getAppCheckHeader = async (
  appCheck?: AppCheck,
): Promise<Record<string, string>> => {
  if (!appCheck) {
    return {};
  }

  try {
    const result = await getToken(appCheck);
    return { 'x-firebase-appcheck': result.token };
  } catch (e) {
    captureException(e);
    return {};
  }
};
