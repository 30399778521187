import React, { useCallback, useEffect, useState } from 'react';
import { IdleTimerProvider, useIdleTimerContext } from 'react-idle-timer';

import { useInactivityProviderQuery } from 'graphql/types';
import { Duration, ZonedDateTime } from '@js-joda/core';
import { ModalContainer } from 'components/common/Modal';
import { Button } from 'components/common/Button';
import { useAuth } from '@teamexos/fit-shared';
import styled from '@emotion/styled';
import useMixpanel from 'hooks/useMixpanel';

const Wrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: '326px',
  paddingLeft: theme.baseUnit * 2,
  paddingRight: theme.baseUnit * 2,
  paddingBottom: theme.baseUnit * 2,
  paddingTop: theme.baseUnit * 5,
  backgroundColor: theme.colors.alias.mainBackground,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  borderRadius: theme.baseUnit,
}));

export const Title = styled('h1')(({ theme }) => ({
  ...theme.typography.textVariant.title.s,
  color: theme.colors.alias.textPrimary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 2,
}));

export const Subtitle = styled('p')(({ theme }) => ({
  ...theme.typography.textVariant.subtitle.s,
  color: theme.colors.alias.textSecondary,
  marginTop: 0,
  marginBottom: theme.baseUnit * 5,
}));

const Prompt = ({ open }: { open: boolean }) => {
  const [remaining, setRemaining] = useState<number>(0);

  const { activate, getRemainingTime } = useIdleTimerContext();

  const track = useMixpanel();

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <ModalContainer open={open}>
      <Wrapper>
        <Title>Are you still here?</Title>
        <Subtitle>Logging out in {remaining} seconds</Subtitle>
        <Button
          onClick={() => {
            activate();
            track('User Action', {
              event_category: 'sign-in',
              event_location: 'sign_out_modal',
              event_name: 'stay_signed_in',
              event_type: 'screen interaction',
            });
          }}
        >
          Yes, I&apos;m here
        </Button>
      </Wrapper>
    </ModalContainer>
  );
};

const INACTIVITY_TIME = '@inactivityTime';

const InactivityProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { data } = useInactivityProviderQuery();
  const inactivityDuration = data?.self?.fitClient?.inactivityDuration
    ? Duration.parse(data.self.fitClient.inactivityDuration)
    : null;
  const clientUsesInactivityTracking = !!inactivityDuration;
  const track = useMixpanel();

  const [promptOpen, setPromptOpen] = useState(false);
  const { signOut, isLoggedIn } = useAuth();

  const doSignout = useCallback(() => {
    localStorage.removeItem(INACTIVITY_TIME);
    signOut(false);
  }, [signOut]);

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }
    const previousInactivityTime = localStorage.getItem(INACTIVITY_TIME);

    if (previousInactivityTime) {
      const inactivityTime = ZonedDateTime.parse(previousInactivityTime);
      const remainingTime = Duration.between(
        ZonedDateTime.now(),
        inactivityTime,
      );

      if (remainingTime.toMillis() <= 0) {
        doSignout();
      }
    }
  }, [isLoggedIn, doSignout]);

  return (
    <IdleTimerProvider
      timeout={inactivityDuration?.toMillis()}
      // Give 1 minute notice of being signed out
      promptBeforeIdle={Duration.ofMinutes(1).toMillis()}
      stopOnIdle
      crossTab
      debounce={500}
      disabled={!clientUsesInactivityTracking}
      onAction={(_, idleTimer) => {
        if ((idleTimer?.getRemainingTime() ?? 0) > 0) {
          localStorage.setItem(
            INACTIVITY_TIME,
            ZonedDateTime.now()
              .plusSeconds((idleTimer?.getRemainingTime() ?? 0) / 1000)
              .toString(),
          );
        }
      }}
      onIdle={() => {
        setPromptOpen(false);
        doSignout();
      }}
      onActive={() => {
        setPromptOpen(false);
      }}
      onPrompt={() => {
        track('Screen View', {
          page_name: 'sign_out_modal',
        });
        setPromptOpen(true);
      }}
    >
      <Prompt open={promptOpen} />
      {children}
    </IdleTimerProvider>
  );
};

export default InactivityProvider;
