import React, { useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Box,
} from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from 'material-ui-popup-state/hooks';
import {
  faArrowRightFromBracket,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/pro-regular-svg-icons';
import exosLogo2x from 'assets/logo@2x.png';
import exosLogo from 'assets/logo.png';
import useMixpanel from 'hooks/useMixpanel';
import { useAuth } from '@teamexos/fit-shared';
import useSplit from 'hooks/useSplit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { usePlaybookTheme } from 'playbook';
import Banner from 'components/common/Banner';
import { useSelfQuery } from '../../graphql/types';

const Header = () => {
  const track = useMixpanel();
  const { signOut } = useAuth();
  const navigate = useNavigate();
  const { data: userData } = useSelfQuery();
  const { flags } = useSplit();
  const theme = usePlaybookTheme();

  const showDeveloperMenu = flags['show-developer-menu'];

  const avatar = `${userData?.self?.avatar?.basePath}/${userData?.self?.avatar?.id}/84x84.jpg`;
  const handleLogout = async () => {
    track('User Action', {
      event_name: 'menu_sign_out',
      event_type: 'screen interaction',
      event_category: 'menu',
      event_location: 'menu',
    });
    await signOut(false);
  };
  const popupState = usePopupState({ variant: 'popover', popupId: 'demoMenu' });
  useEffect(() => {
    if (popupState.isOpen) {
      track('User Action', {
        event_name: 'menu_drop_down',
        event_type: 'screen interaction',
        event_category: 'menu',
        event_location: window.location.pathname,
      });
    }
  }, [popupState.isOpen, track]);

  return (
    <AppHeader position="static" id="authenticatedHeader">
      <Toolbar>
        <LogoContainer>
          <Link to="/" style={{ lineHeight: 0 }}>
            <Logo
              src={exosLogo}
              srcSet={`${exosLogo} 1x,${exosLogo2x} 2x`}
              alt="Exos"
            />
          </Link>
        </LogoContainer>
        <Button
          aria-label="menu"
          aria-controls="simple-menu"
          endIcon={
            <FontAwesomeIcon
              icon={faChevronDown}
              width={32}
              size="lg"
              color={theme.colors.gray[900]}
              style={{ marginBottom: 3 }}
            />
          }
          disableRipple
          style={{
            overflowWrap: 'anywhere',
          }}
          {...bindTrigger(popupState)}
        >
          {userData?.self?.email}
        </Button>
        <Menu
          keepMounted
          {...bindMenu(popupState)}
          classes={{ paper: 'top-0', list: 'no-pad' }}
        >
          <ListItem
            onClick={() => {
              popupState.close();
            }}
          >
            <ListItemIcon>
              <Avatar
                style={{ marginRight: theme.baseUnit }}
                alt={`${userData?.self?.firstName} ${userData?.self?.lastName}`}
                src={avatar}
              />
            </ListItemIcon>
            <ListItemText primary={userData?.self?.email} />
            <FontAwesomeIcon
              icon={faChevronUp}
              width={48}
              size="lg"
              color={theme.colors.gray[900]}
            />
          </ListItem>
          {showDeveloperMenu && (
            <ListItem onClick={() => navigate('/developer')}>
              <ListItemIcon>
                <FontAwesomeIcon
                  icon={faBug}
                  size="lg"
                  width={48}
                  color={theme.colors.gray[900]}
                />
              </ListItemIcon>
              <ListItemText primary="Developer Menu" />
            </ListItem>
          )}
          <ListItem onClick={handleLogout}>
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                width={48}
                size="lg"
                color={theme.colors.gray[900]}
              />
            </ListItemIcon>
            <ListItemText primary="Sign out" />
          </ListItem>
        </Menu>
      </Toolbar>
      <Banner
        text="Looking for Practices and Coaching Sessions? View your full Gameplan on the Exos app."
        id="practicesInfo"
      />
    </AppHeader>
  );
};

export default Header;

const AppHeader = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.colors.gray['1'],
  boxShadow: '0px 0px 12px 0px rgba(0,0,0,0.07)',
}));

const LogoContainer = styled(Box)({
  flexGrow: 1,
  display: 'flex',
  alignItems: 'center',
});

const Logo = styled('img')({ width: '100px', height: 'auto' });

const ListItem = styled(MenuItem)(({ theme }) => ({
  height: theme.baseUnit * 8,
}));
