import React from 'react';
import { Snackbar } from '@mui/material';
import { useSnackbar } from 'contexts/snackbarContext';

const SnackBar = () => {
  const { state } = useSnackbar();
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={state.visible}
      message={state.text}
    />
  );
};

export default SnackBar;
