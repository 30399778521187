import React, { InputHTMLAttributes, useCallback, useRef } from 'react';
import useMixpanel from 'hooks/useMixpanel';
import { titleCase } from 'utils/helpers';
import styled from '@emotion/styled';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.baseUnit,
  width: '100%',
}));

interface InputContainerProps extends React.BaseHTMLAttributes<HTMLDivElement> {
  isInvalid: boolean;
  isDisabled: boolean;
}

const InputContainer: React.FC<InputContainerProps> = styled('div')(
  ({ isInvalid, isDisabled, theme }) => ({
    cursor: isDisabled ? 'default' : 'text',
    backgroundColor: isDisabled
      ? theme.colors.alias.primaryDisabled
      : theme.colors.alias.mainSurface,
    padding: `${theme.baseUnit}px ${theme.baseUnit * 2}px `,
    minHeight: theme.baseUnit * 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.baseUnit,
    borderRadius: theme.baseUnit,
    border: '1px solid',
    borderColor:
      isInvalid && !isDisabled ? theme.colors.alias.statusError : 'transparent',
    '&:focus-within': {
      borderColor: isDisabled
        ? 'transparent'
        : isInvalid
          ? theme.colors.alias.statusError
          : theme.colors.alias.primary,
    },
    '&:hover': {
      borderColor: isDisabled
        ? 'transparent'
        : isInvalid
          ? theme.colors.alias.statusError
          : theme.colors.alias.primary,
    },
  }),
);

const TextContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.baseUnit,
  width: '100%',
  '&:focus-within label': {
    transform: 'translate(0px, -12px) scale(0.8)',
  },
}));
const Input = styled('input')(({ theme }) => ({
  zIndex: 10,
  ...theme.typography.fontFamily.reader400,
  color: theme.colors.alias.textPrimary,
  backgroundColor: 'transparent',
  fontSize: '14px',
  height: theme.baseUnit * 2,
  border: 'none',
  padding: 0,
  outline: 'none',
}));

interface LabelProps extends React.BaseHTMLAttributes<HTMLLabelElement> {
  filled?: boolean;
}

const Label: React.FC<LabelProps> = styled('label')(({ filled, theme }) => ({
  cursor: 'text',
  ...theme.typography.fontFamily.reader400,
  fontSize: '14px',
  lineHeight: '10px',
  letterSpacing: '.5px',
  color: theme.colors.alias.textSecondary,
  position: 'relative',
  top: '14px',
  transformOrigin: 'top left',
  transform: filled ? 'translate(0px, -12px) scale(0.8)' : '',
  transition: '200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
}));

interface HelperTextProps extends React.BaseHTMLAttributes<SVGTextElement> {
  isInvalid: boolean;
  isDisabled: boolean;
}

const HelperText: React.FC<HelperTextProps> = styled('text')(
  ({ isInvalid, isDisabled, theme }) => ({
    ...theme.typography.fontFamily.reader400,
    fontSize: '12px',
    lineHeight: '18px',
    color: isInvalid
      ? theme.colors.alias.statusError
      : isDisabled
        ? theme.colors.alias.textDisabled
        : theme.colors.alias.textSecondary,
  }),
);

export type NewTextFieldProps = {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  helperText?: string;
  iconLeft?: IconDefinition;
  iconRight?: IconDefinition;
  onIconRightClick?: () => void;
  inputProps?: InputHTMLAttributes<HTMLElement>;
  isInvalid?: boolean;
  isDisabled?: boolean;
  mixpanelData?: Record<string, string>;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  style?: React.CSSProperties;
};

/**
 * A new standard text input with a mixpanel event baked in.
 */
const NewTextField = (props: NewTextFieldProps) => {
  const {
    value,
    onChange,
    label,
    helperText,
    iconLeft,
    iconRight,
    onIconRightClick,
    isInvalid = false,
    isDisabled = false,
    onBlur,
    mixpanelData,
    inputProps,
    ...rest
  } = props;

  const track = useMixpanel();

  const inputRef = useRef<HTMLInputElement>(null);

  const trackedOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      track('User Action', {
        event_name: 'text_entered',
        event_type: 'screen interaction',
        event_label: titleCase(label),
        // if you need to override event_label (ex. to preserve case)
        // just pass the preferred event_label in mixpanelData
        ...mixpanelData,
      });

      if (onBlur) {
        onBlur(e);
      }
    },
    [label, mixpanelData, onBlur, track],
  );

  return (
    <Container {...rest}>
      <InputContainer
        onClick={() => inputRef.current?.focus()}
        isInvalid={isInvalid}
        isDisabled={isDisabled}
      >
        {iconLeft && <FontAwesomeIcon icon={iconLeft} style={{ height: 20 }} />}
        <TextContainer>
          <Label filled={!!value}>{label}</Label>
          <Input
            {...inputProps}
            value={value}
            onChange={onChange}
            aria-label={label}
            ref={inputRef}
            onBlur={trackedOnBlur}
            disabled={isDisabled}
          />
        </TextContainer>
        {iconRight && (
          <FontAwesomeIcon
            icon={iconRight}
            style={{
              height: 20,
              cursor: onIconRightClick ? 'pointer' : 'inherit',
            }}
            onClick={onIconRightClick}
          />
        )}
      </InputContainer>
      {helperText && (
        <HelperText isInvalid={isInvalid} isDisabled={isDisabled}>
          {helperText}
        </HelperText>
      )}
    </Container>
  );
};

export default NewTextField;
