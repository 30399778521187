import { captureMessage } from '@sentry/browser';
import { configure } from '@teamexos/fit-shared';

const { origin } = window.location;
const redirectUrl = `${origin}`;

const config: Record<string, any> = {
  dev: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_Trac5E2KH',
    aws_user_pools_web_client_id: '6lfhcc9ji2q1fnd74ab6fvrsh3',
    oauth: {
      domain: 'auth-dev.teamexos.com',
      scope: ['openid'],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: 'code',
    },
  },
  preprod: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_XTZUekv22',
    aws_user_pools_web_client_id: 'lm8vn137ksmpg3lm194kk4qlp',
    oauth: {},
  },
  prod: {
    aws_project_region: 'us-east-1',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_31QwwmhDu',
    aws_user_pools_web_client_id: '4sjq991dncnepu8ad0ujvqgg5h',
    oauth: {
      domain: 'auth.teamexos.com',
      scope: ['openid'],
      redirectSignIn: redirectUrl,
      redirectSignOut: redirectUrl,
      responseType: 'code',
    },
  },
};

if (process.env.REACT_APP_ENV_LEVEL) {
  const authConfig = config[process.env.REACT_APP_ENV_LEVEL];

  if (authConfig) {
    configure(authConfig);
  } else {
    captureMessage('cognito auth config not set');
  }
} else {
  captureMessage('REACT_APP_ENV_LEVEL not set');
}
