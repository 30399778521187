import slugify from 'slugify';
import { CATCH_ALL_GENRE, ClassFiltersT } from 'contexts/classFilterContext';
import {
  ContentAttribute,
  ContentGenreWithChildrenFragment,
} from 'graphql/types';

const durationsMapping: Record<string, number[]> = {
  '5 min': [0, 7],
  '15 min': [7, 22],
  '30 min': [22, 35],
  '45+ min': [35, 99999],
};

export const formatFilters = (
  tags: ClassFiltersT,
  availableBrands: string[],
  availableEquipment: { name: string; value: string }[],
  availableGenres: ContentGenreWithChildrenFragment[],
  availableLevels: { name: string; value: string }[],
  availableSplits: Pick<ContentAttribute, 'name' | 'value'>[],
  availableIntensities: Pick<ContentAttribute, 'name' | 'value'>[],
) => {
  const filter: Record<string, unknown> = {
    component: 'view-ondemand-class',
  };
  if (tags?.brands && tags?.brands.length > 0) {
    filter.brand = tags.brands.map((brand) =>
      slugify(brand, { replacement: '_', lower: true }),
    );
  } else {
    filter.brand = availableBrands.map((brand) =>
      slugify(brand, { replacement: '_', lower: true }),
    );
  }
  if (tags?.genres && tags.genres.length > 0) {
    const { genres } = tags;

    // search for the genre in the parent and children genres
    const genresWithChildren = availableGenres.flatMap((genre) => [
      genre,
      ...genre.children,
    ]);

    filter.genre = genres
      .map((e: string) => {
        if (e === CATCH_ALL_GENRE.value) {
          return ''; // Omit
        }

        return genresWithChildren.find((genre) => e === genre.value)?.value;
      })
      .filter(Boolean);
  }
  if (tags?.durations && tags.durations.length > 0) {
    const durationMinMax: number[] = [];
    tags?.durations.forEach((duration: string) => {
      durationMinMax.push(...(durationsMapping[`${duration}`] || []));
    });
    filter.duration = durationMinMax;
  }
  if (tags?.levels && tags.levels.length > 0 && availableLevels.length > 0) {
    const levelValues = tags.levels.map((tag) => {
      const foundLevel = availableLevels.find((l) => l.name === tag);
      return foundLevel?.value;
    });
    filter.level = levelValues.filter(Boolean);
  }
  if (tags?.splits && tags.splits.length > 0 && availableSplits.length > 0) {
    const splitValues = tags.splits.map((tag) => {
      const foundSplit = availableSplits.find((s) => s.name === tag);
      return foundSplit?.value;
    });
    filter.split = splitValues.filter(Boolean);
  }
  if (
    tags?.intensities &&
    tags.intensities.length > 0 &&
    availableIntensities.length > 0
  ) {
    const intensityValues = tags.intensities.map((tag) => {
      const foundIntensity = availableIntensities.find((s) => s.name === tag);
      return foundIntensity?.value;
    });
    filter.intensity = intensityValues.filter(Boolean);
  }
  if (
    tags?.equipment &&
    tags.equipment.length > 0 &&
    availableEquipment.length > 0
  ) {
    const equipmentList = tags.equipment.map((equipment) => {
      const foundEquipment = availableEquipment.find(
        (e) => e.name === equipment,
      );
      return foundEquipment?.value;
    });
    filter.equipment = equipmentList.filter(Boolean);
  }
  return filter;
};
