import './wdyr';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import smoothscroll from 'smoothscroll-polyfill';
import * as Sentry from '@sentry/react';
import { browserTracingIntegration } from '@sentry/browser';

import logger from 'utils/logger';
import { routerIntegration } from 'utils/sentry';

import App from './App';
import './index.css';
import Providers from './providers';
import './auth';

// Polyfill to use smooth scrolling APIs without custom implementation
smoothscroll.polyfill();

if (process.env.REACT_APP_SENTRY_DSN) {
  logger.debug('Initializing Sentry');

  // https://docs.sentry.io/platforms/javascript/guides/react/
  Sentry.init({
    environment: process.env.NODE_ENV || 'development',
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [browserTracingIntegration(), routerIntegration],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.1,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  </React.StrictMode>,
);
