import React from 'react';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePlaybookTheme } from 'playbook';
import { ReactComponent as AlertIcon } from 'assets/icons/alert-circle-outline.svg';
import { Hint } from 'pages/login/styles';
import { Button } from 'components/common/Button';

const CenteredView = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  flex: 1,
});

const SecondaryButton = styled(MuiButton)(() => ({
  backgroundColor: 'transparent',
}));

const Icon = styled(AlertIcon)(({ theme }) => ({
  color: theme.colors.gray[800],
  marginBottom: theme.baseUnit * 3,
}));

const Title = styled('h1')(({ theme }) => ({
  color: theme.colors.gray[900],
  ...theme.typography.fontFamily.sharp600,
  textAlign: 'center',
  fontSize: 24,
  lineHeight: '52px',
  margin: `0 0 ${theme.baseUnit}px 0`,
}));

const Text = styled('p')(({ theme }) => ({
  fontSize: 18,
  ...theme.typography.fontFamily.reader400,
  lineHeight: 1.25,
  color: theme.colors.gray[500],
  margin: `0 0 ${theme.baseUnit * 5}px 0`,
  textAlign: 'center',
}));

type Props = {
  buttonText?: string;
  buttonType?: 'primary' | 'secondary';
  titleText?: string;
  errorTextNode?: React.ReactNode;
  onClick?: () => void;
};
const Error: React.FC<Props> = ({
  buttonText = 'Go back',
  buttonType = 'primary',
  titleText = 'Oh no!',
  errorTextNode = <>Looks like something went wrong.</>,
  onClick = () => {},
}) => {
  const theme = usePlaybookTheme();

  return (
    <CenteredView>
      <Icon aria-label="alert icon" />
      <Title>{titleText}</Title>

      <Text>{errorTextNode}</Text>
      {buttonType === 'primary' ? (
        <Button
          onClick={onClick}
          style={{
            ...theme.typography.textVariant.button.l,
            padding: `${theme.baseUnit * 2}px ${theme.baseUnit * 5}px`,
          }}
        >
          {buttonText}
        </Button>
      ) : (
        <SecondaryButton onClick={onClick}>
          <Hint
            style={{
              textTransform: 'none',
              fontSize: 16,
              textDecorationLine: 'underline',
            }}
          >
            {buttonText}
          </Hint>
        </SecondaryButton>
      )}
    </CenteredView>
  );
};

export default Error;
