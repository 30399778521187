import type { TypePolicies } from '@apollo/client';

import { FieldNode, SelectionNode } from 'graphql';
import { relayStylePagination } from '@apollo/client/utilities';

import fragments from '../graphql/fragments.json';

const typePolicies: TypePolicies = {
  PracticeProgram: {
    merge: true,
  },
  Challenge: {
    merge: true,
  },
  Client: {
    merge: true,
  },
  User: {
    merge: true,
  },
  ChatRoomMessagesConnection: {
    merge: true,
  },
  ContentSection: {
    fields: {
      contents: relayStylePagination(),
    },
  },
  ContentEdge: {
    merge: true,
    keyFields: ['cursor'],
  },
  ContentCollectionSectionsConnection: {
    merge: true,
  },
  Content: {
    merge: true,
  },
  Query: {
    fields: {
      mboClass: {
        read(_, { args, toReference }) {
          const ref = toReference({
            __typename: 'MboClass',
            id: args?.input.id,
          });

          return ref;
        },
      },
      content: {
        read(_, { canRead, toReference, args }) {
          // If we find the object in the cache because it was loaded in a list, return it
          const contentType = fragments.possibleTypes.Content.find((type) =>
            canRead(
              toReference({
                __typename: type,
                id: args?.id,
              }),
            ),
          );

          return toReference({
            __typename: contentType,
            id: args?.id,
          });
        },
      },
      self: {
        merge: true,
        keyArgs(_, context) {
          const isSurvey = !!context?.field?.selectionSet?.selections.find(
            (selectionNode: SelectionNode) => {
              const { name } = selectionNode as FieldNode;
              return name && name.value === 'surveys';
            },
          );
          return `self${isSurvey ? '-survey' : ''}`;
        },
      },
    },
  },
};

export default typePolicies;
