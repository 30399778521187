import React from 'react';
import { styled } from '@mui/material/styles';
import { getTimezone } from 'utils/dates';

export type TimeDisplayProps = {
  start: string | Date;
  end?: string | Date;
  dayName?: boolean;
  timezone?: boolean;
  onlyTime?: boolean;
  className?: string;
  gTimeFmt?: (t: string) => string;
};

const gDayName = (date: Date) =>
  date.toLocaleString('en-us', {
    weekday: 'long',
  });

// May 3
const gDay = (date: Date) =>
  date.toLocaleString('en', {
    month: 'short',
    day: 'numeric',
  });

// 8:30AM
const gTime = (date: Date) =>
  date.toLocaleString('en', {
    hour: 'numeric',
    minute: 'numeric',
  });

const sameDay = (d1: Date, d2: Date) =>
  d1.getDate() === d2.getDate() &&
  Math.abs(d1.getTime() - d2.getTime()) < 24 * 60 * 60 * 1000;

const TimeDisplay: React.FC<TimeDisplayProps> = (props: TimeDisplayProps) => {
  const {
    start,
    end,
    dayName,
    timezone,
    onlyTime,
    className,
    gTimeFmt = (t: string) => t,
  } = props;

  const sDate = new Date(start);

  const sDayName = gDayName(sDate);
  const sDayStr = gDay(sDate);
  const sTimeStr = gTimeFmt(gTime(sDate));

  const eDate = end ? new Date(end) : new Date(); // won't get shown if missing
  const eDayName = gDayName(eDate);
  const eDayStr = gDay(eDate);
  const eTimeStr = gTimeFmt(gTime(eDate));

  const ts = getTimezone();

  // May 3rd · 3:40pm - May 5th · 5am
  // May 2nd · 3pm - 4pm
  let date = [
    dayName ? `${sDayName}, ` : null,
    sDayStr,
    ' · ',
    sTimeStr,
    ...(end
      ? [
          ' - ',
          ...(sameDay(sDate, eDate)
            ? []
            : [dayName ? `${eDayName}, ` : null, eDayStr, ' · ']),
          eTimeStr,
        ]
      : []),
    timezone && ts ? ` ${ts}` : null,
  ].join('');

  if (onlyTime) {
    date = [
      sTimeStr,
      ...(end ? [' - ', eTimeStr] : []),
      timezone && ts ? ` ${ts}` : null,
    ].join('');
  }

  return (
    <Time className={className} dangerouslySetInnerHTML={{ __html: date }} />
  );
};

const Time = styled('span')(({ theme }) => ({
  fontSize: 12,
  textTransform: 'uppercase',
  ...theme.typography.fontFamily.sharp600,
  display: 'block',
}));

export default TimeDisplay;
