import {
  Container as MUIContainer,
  ContainerTypeMap,
  styled,
} from '@mui/material';
import { PaletteProps } from '@mui/system';
import React from 'react';

type Props = ContainerTypeMap['props'] & {
  className?: string;
} & PaletteProps;
const Container: React.FC<Props> = ({
  children,
  className,
  ...rest
}: Props) => (
  <ContainerStyled maxWidth="xl" className={className} {...rest}>
    {children}
  </ContainerStyled>
);

const ContainerStyled = styled(MUIContainer)(({ theme }) => ({
  paddingLeft: theme.spacing(10.5),
  paddingRight: theme.spacing(10.5),
  paddingTop: theme.spacing(10.5 / 2),
  paddingBottom: theme.spacing(10.5 / 2),
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(4),
  },
}));

export default Container;
